





























import { WsGiftMessage } from "@/lib/ws";
import { liveStore } from "@/store";
import { Vue, Component, Prop } from "vue-property-decorator";
import Odometer from "@/components/odometer.vue";
interface FloatGiftMessage {
  avatar: string;
  nickname: string;
  tipname: string;
  timestamp: number;
  amount: number;
  isExpired: boolean;
  isAnimationStop: boolean;
  iconUrl: string;
}
@Component({
  components: {
    Odometer,
  },
})
export default class FloatGift extends Vue {
  gifts: FloatGiftMessage[] = [];
  mounted() {
    this.init();
  }
  async init() {
    this.initWebsocket();
  }
  initWebsocket() {
    this.$ws.addEventListener("giftEnter", this.receiveGift);
  }
  get transitionPivot(): number {
    if (document.body.clientHeight > 768) {
      return 2;
    } else {
      return 1;
    }
  }

  receiveGift(e: MessageEvent): void {
    if (!liveStore.isShowPrivateCover) {
      let message: WsGiftMessage = JSON.parse(e.data);
      const gift: FloatGiftMessage = {
        avatar: message.avatar,
        nickname: message.nickname,
        tipname: message.tipname,
        amount: message.num,
        timestamp: Date.now(),
        iconUrl: message.tipimg,
        isExpired: false,
        isAnimationStop: false,
      };

      setTimeout(() => {
        gift.isExpired = true;
        this.gifts.shift();
      }, 4000);
      setTimeout(() => {
        gift.isAnimationStop = true;
      }, 200);
      if (this.gifts.length >= this.transitionPivot + 1) {
        this.gifts.shift();
      }
      this.$nextTick(() => {
        this.gifts.push(gift);
      });
    }
  }
}
