


















import { Vue, Component, Prop } from "vue-property-decorator";
import { Event } from "@/components/events/events.vue";
@Component({
  name: "EventPreview",
})
export default class EventPreview extends Vue {
  @Prop({ type: Event, required: true }) _ev!: Event;
  onclick(): void {
    this.$emit("onclick");
  }
}
