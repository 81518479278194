










import { Vue, Component, Prop } from "vue-property-decorator";
import Purchase from "@/components/purchase.vue";
import VModal from "vue-js-modal";
import { liveStore } from "@/store";
import moment from "moment";
Vue.use(VModal);
@Component({})
export default class Forecast extends Vue {
  @Prop({ required: true }) remainingSeconds!: number;

  humanRemainTime = "--:--";
  currentRemainSeconds = 0;
  remainTicker: number | null = null;

  created(): void {
    this.currentRemainSeconds = Math.round(this.remainingSeconds);
    this.humanRemainTime = this.formatTimer(this.currentRemainSeconds);
    this.remainTicker = setInterval(() => {
      this.currentRemainSeconds -= 1;
      this.humanRemainTime = this.formatTimer(this.currentRemainSeconds);
      if (this.currentRemainSeconds <= 0 && this.remainTicker) {
        clearInterval(this.remainTicker);
        liveStore._isShowPrivateModeForecast(false)
        liveStore._isShowPrivateCover(true);
      }
    }, 1000);
  }
  beforeDestroy(): void {
    if (this.remainTicker) {
      clearInterval(this.remainTicker);
    }
  }
  formatTimer(seconds: number, format = "mm:ss"): string {
    return moment().startOf("day").add(seconds, "seconds").format(format);
  }
  purchase(): void {
    this.$modal.show(
      Purchase,
      {},
      {
        classes: "purchase-modal live-module",
        styles: `border-radius: 5px;max-width: 100vw;`,
        adaptive: true,
        height: "auto",
        width: 350,
      }
    );
  }
}
