








/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { liveStore } from "@/store";
import {
  ConnectionState,
  IAgoraRTCRemoteUser,
  IRemoteAudioTrack,
  IRemoteVideoTrack,
  UID,
} from "agora-rtc-sdk-ng";
import { BuyTicketRes, clientApi } from "@/lib/api";
import { utilityApi } from "@/lib/util";
import type { Ga4Params } from "@/store/live";
import Noty from "noty";
import moment from "moment";
import postMessageDealGiftSuccess from "@/helpers/post-message-deal-gift-success";

const RENEW_TOKEN_PRESET_IN_SECONDS = 15;
const CHECKING_AGORA_TOKEN_INTERVAL_IN_SECONDS = 15;
@Component({})
export default class Video extends Vue {
  isJoined = false;
  renewTokenTimer: number | null = null;
  myAgoraUid: UID | null = null;
  get agoraClient() {
    return liveStore.agoraClient;
  }
  get bg(): string {
    return liveStore.backgroundImageUrl;
  }
  get canGetAgoraToken(): boolean {
    return liveStore.canGetAgoraToken;
  }
  get renewAgoraTokenTrigger(): number {
    return liveStore.renewTokenTrigger;
  }
  get isMobile(): boolean {
    return liveStore.isMobile;
  }
  get ga4ClientId(): string {
    return liveStore.ga4ClientId || "";
  }
  get ga4Params(): Ga4Params {
    return liveStore.ga4Params;
  }

  @Watch("canGetAgoraToken")
  onCanGetAgoraTokenChanged(val: boolean) {
    if (val && this.isJoined) {
      this.renewAgoraToken(liveStore.liveId || 0);
    } else if (val && !this.isJoined) {
      this.init();
    }
  }
  @Watch("renewAgoraTokenTrigger")
  onRenewAgoraToken() {
    this.renewAgoraToken(liveStore.liveId);
  }
  @Watch("isMobile")
  onIsMobileChanged(val: boolean): void {
    const isMobile = val;
    liveStore.videoTracks.map((track) => {
      track.stop();
      track.play(`video_container`, {
        fit: isMobile ? "cover" : "contain",
      });
    });
  }

  async mounted() {
    this.init();
  }

  async init() {
    this.agoraClient.leave();
    const res = await clientApi.getAgoraParams(
      liveStore.liveId,
      liveStore.nahuoToken || ""
    );
    if (res.code === 1) {
      // 成功取得agora token
      this.agoraClient.setClientRole("audience");
      // add event listener to play remote tracks when remote user publishs.
      this.agoraClient.on("user-published", this.onPublished);
      this.agoraClient.on("user-unpublished", this.onUnpublished);
      this.agoraClient.on(
        "connection-state-change",
        (currentState: ConnectionState, previousState: ConnectionState) => {
          if (currentState === "DISCONNECTED") {
            this.isJoined = false;
          }
        }
      );

      // join the channel
      this.myAgoraUid = await this.agoraClient.join(
        res.data.app_id,
        res.data.channel,
        res.data.token,
        ""
        // 用隨機uid進入agora channel
      );
      this.isJoined = true;
      this.renewAgoraToken(liveStore.liveId);
    } else {
      // new Noty({text:"視訊連結失敗，請重新整理後再試"}).show()
    }
  }

  async reJoin(): Promise<void> {
    const res = await clientApi.getAgoraParams(
      liveStore.liveId,
      liveStore.nahuoToken || ""
    );
    if (res.code === 1) {
      // join the channel
      const id = await this.agoraClient.join(
        res.data.app_id,
        res.data.channel,
        res.data.token,
        ""
        // 用隨機uid進入agora channel
      );
      this.isJoined = true;
    }
    this.bookNextCheck(liveStore.liveId);
  }

  bookNextCheck(liveId: number) {
    this.renewTokenTimer && clearTimeout(this.renewTokenTimer);
    this.renewTokenTimer = setTimeout(() => {
      this.renewAgoraToken(liveId);
    }, CHECKING_AGORA_TOKEN_INTERVAL_IN_SECONDS * 1000);
  }
  async renewAgoraToken(liveId: number) {
    this.renewTokenTimer && clearTimeout(this.renewTokenTimer);

    const res = await clientApi.getAgoraParams(
      liveId,
      liveStore.nahuoToken || ""
    );
    liveStore._isInPrivate(res.is_private_time ? res.is_private_time : false);
    if (this.isJoined) {
      if (res.code === 1) {
        // 成功取得agora token
        this.agoraClient.renewToken(res.data.token);
        liveStore._isShowPrivateCover(false);
        liveStore._isShowEndCover(false);

        this.renewTokenTimer = setTimeout(() => {
          this.renewAgoraToken(liveId);
        }, res.data.expire * 1000 - Date.now() - RENEW_TOKEN_PRESET_IN_SECONDS * 1000);
      } else if (res.code === -10) {
        // 私密直播間且沒有登入
        liveStore._isShowPrivateCover(true);
        this.bookNextCheck(liveId);
      } else if (
        res.code === -20 &&
        liveStore.nahuoToken &&
        liveStore.accessToken
      ) {
        // 無法取得agora token: 需要購買門票
        if (liveStore.isConfirmedContinuousBuyingPrivateTicket &&
          liveStore.roomDetail &&
          liveStore.nahuoToken &&
          liveStore.accessToken
        ) {
          const result: BuyTicketRes = await clientApi.buyTicket({
            liveId: liveStore.roomDetail?.data.detail.live_id,
            nahuoToken: liveStore.nahuoToken,
            accessToken: liveStore.accessToken,
            ga4ClientId: this.ga4ClientId,
            ga4Params: this.ga4Params,
          });
          if (result.code === 1) {
            // 購買房卡成功
            postMessageDealGiftSuccess()
            new Noty({ text: "購買房卡成功" }).show();
            this.$ws.sendWebsocketMsg("giftEnter", {
              toUser: "all",
              tipid: result.data.tipid,
              tipimg: result.data.tipimg,
              tipname: result.data.tipname,
              money: result.data.money,
              num: result.data.num,
            });
            this.renewAgoraToken(liveId);
          } else {
            // 購買房卡失敗
            new Noty({ text: result.msg }).show();
            liveStore._isShowPrivateCover(true);
            liveStore._isConfirmedContinuousBuyingPrivateTicket(false);
            this.bookNextCheck(liveId);
          }
        } else {
          liveStore._isShowPrivateModeForecast(false);
          liveStore._isShowPrivateCover(true);
          this.bookNextCheck(liveId);
        }
      } else {
        // 無法取得agora token: 其他原因，可能是下播了
        liveStore.init(liveStore.currentLadyUid);
        // liveStore._isShowEndCover(true);
        // this.bookNextCheck(liveId);
      }
    } else {
      this.reJoin();
    }
  }

  async onPublished(user: IAgoraRTCRemoteUser, mediaType: "audio" | "video") {
    if (
      mediaType === "video" &&
      user.uid === parseInt(process.env.VUE_APP_LIVE_PUBLISH_ID)
    ) {
      const track: IRemoteVideoTrack = await this.agoraClient.subscribe(
        user,
        mediaType
      );
      liveStore.pushVideoTrack(track);
      let video_container = document.getElementById("video_container");
      if (video_container) {
        document
          .getElementById("video_container")
          ?.parentElement?.removeChild(video_container);
      }
      var myvideo = document.createElement("div");
      myvideo.className = "myvideo";
      myvideo.setAttribute("id", "video_container");
      document.querySelector("#livevideo")?.appendChild(myvideo);
      const isMobile = utilityApi.isMobile();
      track.play(`video_container`, {
        fit: isMobile ? "cover" : "contain",
      });
    }
    if (
      mediaType === "audio" &&
      user.uid === parseInt(process.env.VUE_APP_LIVE_PUBLISH_ID)
    ) {
      const track: IRemoteAudioTrack = await this.agoraClient.subscribe(
        user,
        mediaType
      );
      liveStore.pushAudioTrack(track);
      liveStore._audioRemoteUser(user);
      // console.log("===============================================");
      // console.log("a. 测试的频道号: ", this.agoraClient.channelName);
      // console.log(
      //   "b. 发流的移动端web用户的uid: ",
      //   user.uid,
      //   "(備註：發流的端口為ios& android sdk, 僅聽流端使用web sdk)"
      // );
      // console.log("c. 远端听发流端声音无变化的用户uid: ", this.myAgoraUid);
      // console.log("d. 记录一下测试时间: ", moment().format());
      // console.log("e. 提供一下您这边的console.log");
      // console.log("===============================================");
      // track.play();
    }
  }

  onUnpublished(user: IAgoraRTCRemoteUser) {
    liveStore._audioRemoteUser(null);
    liveStore._videoRemoteUser(null);
    liveStore.initAudioTrack()
    liveStore.initVideoTrack()
  }

  beforeDestroy() {
    this.agoraClient.leave();
  }
}
