






import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})
export default class BackToTop extends Vue {
  backToTop(): void {
    const liveDOM = document.querySelector(".live") as HTMLDivElement;
    console.log("backToTop: ", liveDOM?.parentElement);
    liveDOM?.parentElement?.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }
}
