






















































































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { liveStore } from "@/store";
import { clientApi } from "@/lib/api";
import type { Ga4Params } from "@/store/live";
import Noty from "noty";
import { utilityApi } from "@/lib/util";
import postMessageBuyDiamond from "@/helpers/post-message-buy-diamond";
import postMessageDealGiftSuccess from "@/helpers/post-message-deal-gift-success";

interface Category {
  name: string;
  key: GiftCategory;
}
interface IGift {
  // 該禮物註冊在那火裡的id
  nahuoId: number;
  // 該禮物註冊在jkf裡的id
  jkfId: number;
  // 禮物名稱
  name: string;
  // 禮物紅鑽價格
  price: number;
  // 禮物圖片
  iconUrl: string;
  // 禮物svga檔案位置，此欄位僅在category為FULL時適用
  svgaUrl: string;
  // 所屬活動的icon，同時可用來判斷是否屬於活動限定禮物
  labelUrl: string | null;
  // GiftCategory => ALL:全部(=不分類), FULL:全版動畫, STAMP:貼圖
  category: GiftCategory;
}
enum GiftCategory {
  ALL = "ALL",
  FULL = "FULLSCREEN",
  STAMP = "STAMP",
  ACTIVITY = "ACTIVITY",
}
@Component({
  name: "donate",
})
export default class Donate extends Vue {
  @Prop({ type: Boolean, default: true }) showHead!: boolean;

  categories: Category[] = [
    { key: GiftCategory.ALL, name: "全部" },
    { key: GiftCategory.ACTIVITY, name: "活動" },
    { key: GiftCategory.FULL, name: "全版動畫" },
    { key: GiftCategory.STAMP, name: "貼圖" },
  ];
  currentCategory: GiftCategory = GiftCategory.ALL;
  selectedGift: IGift | null = null;
  isShowCheckout = false;
  isLoading = false;
  isLoadingBalance = false;
  isSending = false;
  balance = 0;
  donateAmount = 1;
  get filteredGifts(): IGift[] {
    if (this.currentCategory === GiftCategory.ALL) {
      return this.gifts;
    } else if (this.currentCategory === GiftCategory.ACTIVITY) {
      return this.gifts.filter((e) => e.labelUrl !== null);
    } else {
      return this.gifts.filter((e) => e.category === this.currentCategory);
    }
  }
  get gifts(): IGift[] {
    return liveStore.gifts.map((e, index) => {
      let category = GiftCategory.ALL;
      if (e.animationScope === "stamp") {
        category = GiftCategory.STAMP;
      }
      if (e.animationScope === "full_screen") {
        category = GiftCategory.FULL;
      }
      return {
        nahuoId: e.gift_id,
        jkfId: 0,
        name: e.gift_name,
        price: e.coin,
        iconUrl: e.image_url,
        svgaUrl: "",
        labelUrl: e.event_label,
        category: category,
      };
    });
  }
  get humanBalance(): string {
    return utilityApi.numberWithCommas(this.balance);
  }
  get accessToken(): string {
    return liveStore.accessToken || "";
  }
  get ga4ClientId(): string {
    return liveStore.ga4ClientId || "";
  }
  get ga4Params(): Ga4Params {
    return liveStore.ga4Params;
  }
  get isAffordable(): boolean {
    return this.balance >= (this.selectedGift?.price || 0) * this.donateAmount;
  }
  get customerServiceUrl(): string {
    return `${process.env.VUE_APP_FACE_HOST}/customer-service`;
  }
  created(): void {
    Vue.prototype.GiftCategory = GiftCategory;
  }
  beforeDestroy(): void {
    Vue.prototype.GiftCategory = undefined;
    window.removeEventListener("message", this.refresh, false);
  }
  mounted(): void {
    this.init();
    window.addEventListener("message", this.refresh, false);
  }
  refresh(ev: MessageEvent): void {
   if (ev.data?.action === "isDiamondDealSuccess") {
     if (ev.data?.payload) {
      new Noty({
        text: "儲值完成",
      }).show();
     } else  {
      new Noty({
        text: "儲值失敗請重試",
      }).show();
     }
    this.init();
   }
  }
  async init(): Promise<void> {
    this.isLoading = true;
    try {
      this.balance = await clientApi.getDiamondAmount(
        this.accessToken
      );
    } catch (error) {
      console.log(error);
      this.close();
      new Noty({ text: `無法取得鑽石餘額，請稍候再試` }).show();
    }
    this.isLoading = false;
  }
  close(): void {
    this.$emit("showDonate", false);
    const isMobile = liveStore.isMobile;
    if (isMobile) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.$modal.hide(this.$parent.name);
    }
  }
  setCurrentCategory(category: GiftCategory): void {
    this.currentCategory = category;
  }
  async chooseGift(gift: IGift): Promise<void> {
    this.selectedGift = gift;
    this.isShowCheckout = true;
    this.isLoadingBalance = true;
    try {
      this.balance = await clientApi.getDiamondAmount(
        this.accessToken
      );
    } catch (error) {
      console.log(error);
      this.closeCheckout();
      new Noty({ text: `無法取得鑽石餘額，請稍候再試` }).show();
    }
    this.isLoadingBalance = false;
  }
  closeCheckout(): void {
    this.isShowCheckout = false;
    this.selectedGift = null;
    this.donateAmount = 1;
  }
  async checkout(): Promise<void> {
    if (
      this.selectedGift &&
      liveStore.liveDetail &&
      liveStore.nahuoToken &&
      liveStore.accessToken &&
      !this.isSending
    ) {
      this.isSending = true;
      const res = await clientApi.sendGift(
        {
          giftId: this.selectedGift.nahuoId,
          liveId: liveStore.liveDetail?.detail.live_id,
          anchorId: liveStore.liveDetail?.anchor.anchor_id,
          nahuoToken: liveStore.nahuoToken,
          accessToken: liveStore.accessToken,
          amount:this.donateAmount,
          ga4ClientId: this.ga4ClientId,
          ga4Params: this.ga4Params,
        }
      );
      if (res.code === 1 && res.msg === "success") {
        postMessageDealGiftSuccess()
        new Noty({
          text: "購買禮物成功",
        }).show();
        if (
          res.data.tipname === "百大玉兔" 
        ) {
          new Noty({
            text: `${liveStore.streamerName} 獲得百大女郎投票${
              res.data.num * 10
            }票`,
          }).show();
        }
        this.$ws.sendWebsocketMsg("giftEnter", {
          toUser: "all",
          tipid: res.data.tipid,
          tipimg: res.data.tipimg,
          tipname: res.data.tipname,
          money: res.data.money,
          num: res.data.num,
        });
        this.close();
      } else {
        new Noty({
          text: res.msg,
          type: "error",
        }).show();
      }
      this.isSending = false;
    } else {
      // new Noty({
      //   text: "請重試",
      //   type: "error",
      // }).show();
    }
    this.closeCheckout();
  }

  charge(): void {
    postMessageBuyDiamond()
  }

  chargeWhenCheckout(): void {
    this.closeCheckout();
    this.$nextTick(() => {
      this.charge();
    });
  }

  plusDonateAmount(): void {
    const Intervals = [10, 20, 50, 100];
    const targetAmount =
      Intervals.find((e) => this.donateAmount < e) ||
      Intervals[Intervals.length - 1];
    this.donateAmount = targetAmount;
  }
  minusDonateAmount(): void {
    if (this.donateAmount > 1) {
      this.donateAmount -= 1;
    }
  }
}
