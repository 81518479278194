








































































































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import throttle from "lodash.throttle";
import FloatChat from "@/components/float-chat.vue";
import FloatGift from "@/components/float-gift.vue";
import Forecast from "@/components/forecast.vue";
import PrivateCover from "@/components/private_cover.vue";
import EndCover from "@/components/end_cover.vue";
import HeartBubble from "@/components/heart_bubble.vue";
import ChatInput from "@/components/chat_input.vue";
import Animator from "@/components/animator.vue";
import { liveStore } from "@/store";
import { clientApi, RankAudience } from "@/lib/api";
import VModal from "vue-js-modal";
Vue.use(VModal);
import RankVue from "@/components/rank.vue";
import CurrentEvent from "@/components/current_event.vue";
import { IAgoraRTCRemoteUser, IRemoteAudioTrack } from "agora-rtc-sdk-ng";
import Cookies from "js-cookie";
import { utilityApi } from "@/lib/util";
import Donate from "./donate.vue";
import Noty from "noty";
import 'animate.css/animate.min.css';
import postMessageBackToFaceProfile from "@/helpers/post-message-back-to-face-profile";
import postMessageFollowFace from "@/helpers/post-message-follow-face"; 
import postMessageLogin from'@/helpers/post-message-login'

@Component({
  components: {
    FloatChat,
    FloatGift,
    Forecast,
    PrivateCover,
    EndCover,
    HeartBubble,
    ChatInput,
    Animator,
    CurrentEvent,
  },
})
export default class Control extends Vue {
  isMuted = true;
  isShowAnnouncement = true;
  isShowVolumeMeasure = false;
  toggleVolumeMeasure: any;
  toggleMeasureTimer: number | null = null;
  toggleMute: any;

  currentVolume = parseInt(
    Cookies.get(process.env.VUE_APP_LIVE_VOLUME_COOKIE_NAME) || "50"
  );
  isProcessingFollow = false;
  isShowControlInput = false;

  get remoteUser(): IAgoraRTCRemoteUser | null {
    return liveStore.audioRemoteUser;
  }
  get audioTracks(): IRemoteAudioTrack[] {
    return liveStore.audioTracks;
  }
  get streamerName(): string {
    return liveStore.streamerName;
  }

  get streamerAvatarUrl(): string {
    return liveStore.streamerAvatarUrl;
  }

  get isFollowed(): boolean {
    return liveStore.isFollowed;
  }
  get announcement(): string | null {
    return liveStore.announcement;
  }
  get audienceCount(): number {
    return liveStore.audiencesCount;
  }
  get donatedAudiences(): RankAudience[] {
    return liveStore.onlineAudiences.filter(
      (e) => parseInt(e.donate_amount) > 0
    );
  }
  get top3Audiences(): RankAudience[] {
    return this.donatedAudiences.slice(0, 3);
  }
  get holderCount(): number {
    const num = 3 - this.top3Audiences.length;
    return num > 0 ? num : 0;
  }
  get isShowPrivateModeForecast(): boolean {
    return liveStore.isShowPrivateModeForecast;
  }
  get isShowEndCover(): boolean {
    return liveStore.isShowEndCover;
  }
  get isShowPrivateCover(): boolean {
    return liveStore.isShowPrivateCover;
  }
  get remainingSeconds(): number {
    return liveStore.privateModeTansitionInSeconds;
  }
  get isShowPurchaseModal(): boolean {
    return liveStore.isShowPurchaseModal;
  }
  get isMeHost(): boolean {
    return liveStore.isMeHost;
  }
  get isLogin(): boolean {
    return liveStore.isLogin;
  }
  get isInPrivate(): boolean {
    return liveStore.isInPrivate;
  }
  get isClearMode(): boolean {
    return liveStore.isClearMode;
  }
  get isMobile(): boolean {
    return liveStore.isMobile;
  }
  get chatsAndGiftsHeight(): number {
     if (window.innerHeight > 500) return window.innerHeight * 0.2;
     else if (window.innerHeight > 400) return window.innerHeight * 0.15;
     else return window.innerHeight * 0.1;
  }

  @Watch("currentVolume")
  onCurrentVolumeChanged = throttle((val: number | string) => {
    this.adjustVolume(val);
    Cookies.set(process.env.VUE_APP_LIVE_VOLUME_COOKIE_NAME, String(val));
  }, 500);

  @Watch("isShowPrivateCover")
  onIsShowPrivateCoverChanged(val: boolean, oldVal: boolean): void {
    if (val === true && oldVal === false) {
      this.isMuted = true;
      this.adjustVolume(0);
    }
  }

  @Watch("isInPrivate")
  onIsInPrivateChanged(val: boolean, oldVal: boolean): void {
    if (val === true && oldVal === false) {
      new Noty({
        text: "已進入私密直播房",
        timeout: 10000,
        progressBar: true,
        theme: "jkflive",
      }).show();
    }
  }

  @Watch("audioTracks")
  onAudioTracksChanged(val: IRemoteAudioTrack[]): void {
    if (!this.isMuted) {
      val.map((e) => e.play());
    }
  }

  created(): void {
    this.toggleVolumeMeasure = throttle((bool: boolean, delay = 0) => {
      const measureDOM = document.querySelector(
        ".volume__measure"
      ) as HTMLInputElement;
      this.toggleMeasureTimer && clearTimeout(this.toggleMeasureTimer);
      this.toggleMeasureTimer = setTimeout(() => {
        this.isShowVolumeMeasure = bool;
      }, delay);
      if (bool === false) {
        measureDOM.style.opacity = "0";
        measureDOM.style.transition = `opacity ${delay / 1000}s`;
      } else if (bool === true) {
        measureDOM.style.opacity = "";
        measureDOM.style.transition = "";
      }
    }, 500);

    this.toggleMute = throttle(() => {
      if (this.isMuted) {
        // 開啟聲音
        this.isMuted = false;
        this.adjustVolume();
      } else {
        // 關閉聲音
        this.isMuted = true;
        this.adjustVolume(0);
      }
    }, 500);
  }

  follow(): void {
    postMessageFollowFace(!this.isFollowed)
    liveStore._isFollowed(!this.isFollowed)
  }

  // toggleMute(): void {
  //   if (this.isMuted) {
  //     // 開啟聲音
  //     this.isMuted = false;
  //     this.adjustVolume();
  //   } else {
  //     // 關閉聲音
  //     this.isMuted = true;
  //     this.adjustVolume(0);
  //   }
  // }
  hideAnnouncement(): void {
    this.isShowAnnouncement = false;
  }
  async adjustVolume(num?: number | string): Promise<void> {
    let volumeNumber = 0;
    // if (this.audioTracks.length === 0 && liveStore.audioRemoteUser) {
    //   const track: IRemoteAudioTrack = await liveStore.agoraClient.subscribe(
    //     liveStore.audioRemoteUser,
    //     "audio"
    //   );
    //   liveStore.pushAudioTrack(track);
    //   track.play();
    // }
    if (typeof num === "number") {
      volumeNumber = num;
    } else if (typeof num === "string") {
      volumeNumber = parseInt(num);
    } else if (typeof num === "undefined") {
      volumeNumber = this.currentVolume;
    }
    this.audioTracks.map((e) => e.setVolume(volumeNumber));
    console.log(
      `總共有${this.audioTracks.length}條音軌, 設置音量為: ${volumeNumber}`
    );
    if (volumeNumber > 0) {
      this.audioTracks.map((e) => e.play());
    } else {
      this.audioTracks.map((e) => e.stop());
    }
  }
  showRank(): void {
    const modalHeight = window.innerHeight * 0.7;
    if (this.isMobile) {
      this.$modal.show(
        RankVue,
        {},
        {
          classes: "bottom-attached-modal live-module",
          adaptive: true,
          height: modalHeight,
        }
      );
    } else {
      this.$modal.show(
        RankVue,
        {},
        {
          classes: "live-module",
          styles: `border-radius: 10px;`,
          adaptive: true,
          height: modalHeight,
        }
      );
    }
  }
  showDonate(): void {
    if (this.isMobile) {
      const modalHeight = window.innerHeight * 0.7;
      this.$modal.show(
        Donate,
        {
          showHead: false,
        },
        {
          classes: "bottom-attached-modal live-module",
          adaptive: true,
          height: modalHeight,
        }
      );
    } else {
      liveStore._isShowDonate(true);
    }
  }
  doReturn(): void {
    this.$root.$emit("return");
  }
  login(): void {
    postMessageLogin();
  }
  toggleClearMode(bool: boolean): void {
    liveStore._isClearMode(bool);
  }
  postMessageBackToFaceProfile(): void {
    postMessageBackToFaceProfile()
  }
}
