


































import { Chat } from "@/lib/api";
import { WsGiftMessage, WsUserEnterMessage } from "@/lib/ws";
import { liveStore } from "@/store";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
const USERENTER_COOLDOWN_IN_SECONDS = 300;
@Component({})
export default class FloatChat extends Vue {
  chats: Chat[] = [];
  userEnterCooldownIds: number[] = [];

  @Watch("chats")
  onChatsChanged(val: Chat[]): void {
    const isScreenHeightNotEnough =  window.innerHeight < 500
      if ((isScreenHeightNotEnough && this.chats.length > 3 ) || this.chats.length > 10) {
        this.chats.shift();
      }
  }

  mounted() {
    this.setWebsocket();
  }

  setWebsocket(): void {
    this.$ws.addEventListener("floatchat", this.receiveMessage);
    this.$ws.addEventListener("userEnter", this.receiveUserEnter);
    this.$ws.addEventListener("giftEnter", this.receiveGift);
  }

  receiveMessage(e: MessageEvent): void {
    const message: Chat = JSON.parse(e.data);
    if (!liveStore.bannedNahuoIds.includes(String(message.fromUser))) {
      this.chats.push(message);
      this.$nextTick(() => {
        const area = document.getElementById("float-chat");
        area?.scrollTo(0, area.scrollHeight);
      });
    }
  }

  receiveUserEnter(e: MessageEvent): void {
    const message: WsUserEnterMessage = JSON.parse(e.data);
    if (message.level > 0 && !this.userEnterCooldownIds.includes(message.mid)) {
      this.chats.push({
        banned: 0,
        kick: 0,
        avatar: "",
        content: ` ${message.nickname} 已經進入直播房 ✧*`,
        fromUser: "system",
        gradename: "",
        gradeweight: String(message.level),
        is_virt: "",
        liveid: "",
        manage: "",
        msgid: String(Date.now() + message.nickname + message.level),
        nickname: "",
        rtype: "",
        sendtime: "",
        toUser: "",
      });
      this.userEnterCooldownIds.push(message.mid);
      setTimeout(() => {
        let index = this.userEnterCooldownIds.indexOf(message.mid);
        this.userEnterCooldownIds.splice(index, 1);
      }, USERENTER_COOLDOWN_IN_SECONDS * 1000);
    }
  }

  receiveGift(e: MessageEvent): void {
    const message: WsGiftMessage = JSON.parse(e.data);
    this.chats.push({
      banned: 0,
      kick: 0,
      avatar: "",
      content: `${message.nickname} 贈送${message.tipname} x${message.num}`,
      fromUser: "system",
      gradename: "",
      gradeweight: "",
      is_virt: "",
      liveid: "",
      manage: "",
      msgid: String(Date.now() + message.nickname + message.tipid),
      nickname: "",
      rtype: "",
      sendtime: "",
      toUser: "",
    });
  }

  isMod(chat: Chat): boolean {
    return liveStore.moderatorNahuoIds.includes(String(chat.fromUser));
  }

  isHost(chat: Chat): boolean {
    return liveStore.liveDetail?.anchor.user_id === parseInt(chat.fromUser);
  }

  displayName(originalName: string, isMod = false, isHost = false): string {
    if (isHost) {
      return `${originalName}[主播]`;
    } else if (isMod) {
      return `${originalName}[副控]`;
    } else {
      return originalName;
    }
  }
}
