















import { utilityApi } from "@/lib/util";
import { clientApi } from '@/lib/api'
import { liveStore } from "@/store";
import moment from "moment";
import { Vue, Component } from "vue-property-decorator";
interface MyEvent {
  id: number;
  iconUrl: string;
  giftCount: number;
  landingPageUrl: string;
  giftName: string;
}
const fakeEvent: MyEvent = {
  id: -1,
  giftCount: 0,
  iconUrl: "",
  landingPageUrl: "",
  giftName: "",
};
@Component({})
export default class CurrentEvent extends Vue {
  isShow = moment().isAfter('2022-03-31 23:59:59');
  currentEvent: MyEvent = fakeEvent;
  fetchTicker = 0;
  get humanGiftCount(): string {
    return utilityApi.numberWithCommas(this.currentEvent.giftCount);
  }
  get isMobile(): boolean {
    return liveStore.isMobile;
  }
  mounted(): void {
    this.init();
  }
  beforeDestroy(): void {
    clearInterval(this.fetchTicker);
  }
  async init(): Promise<void> {
    this.currentEvent = await this.fetchCurrentEvent();
    this.fetchTicker = setInterval(async () => {
      this.currentEvent = await this.fetchCurrentEvent();
    }, 30000);
  }
  async fetchCurrentEvent(): Promise<MyEvent> {
    const eventInfos = await clientApi
      .getActivityList(undefined, Number(liveStore.currentLadyUid))
      .then((res) => res.getInfoList());
    const events = eventInfos.map((e, index) => {
      const currentTimestamp = Date.now();
      const startTimestamp = (e.getStartAt()?.getSeconds() || 0) * 1000;
      const endTimestamp = (e.getEndAt()?.getSeconds() || 0) * 1000;
      const isAfterStart = currentTimestamp > startTimestamp;
      const isBeforeEnd = currentTimestamp <= endTimestamp;

      const landingPageUrl = new URL(e.getActivityLinkUrl())
      const newSearch = new URLSearchParams(landingPageUrl.search)
      newSearch.append('t', `${Date.now()}`)
      landingPageUrl.search = newSearch.toString()
      // console.debug('landingPageUrl', landingPageUrl.toString())

      return {
        id: index,
        isOnGoing: isAfterStart && isBeforeEnd,
        iconUrl: e.getGiftImageUrl(),
        giftCount: e.getAmount(),
        landingPageUrl: landingPageUrl.toString(),
        giftName: e.getGiftName(),
      };
    });

    const onGoingEvent = events.find((e) => e.isOnGoing) || fakeEvent;
    return onGoingEvent;
  }
  hideEvent(): void {
    this.isShow = false;
  }
  showLandingPage(): void {
    window.targetLandingPage = this.currentEvent.landingPageUrl;
    this.$modal.show("events-modal");
  }
}
