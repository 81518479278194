import Vue from 'vue'
import Vuex from 'vuex'

import { getModule } from 'vuex-module-decorators'
import Live from '@/store/live'

Vue.use(Vuex)
const store = new Vuex.Store({
  modules: {
    Live,
  }
})

const liveStore: Live = getModule(Live, store)
export {
  liveStore
}
//讓template不用定義直接可以拿store資料
Vue.prototype.liveStore = liveStore
export default store
