










































































import { clientApi } from '@/lib/api';
import { utilityApi } from "@/lib/util";
import { liveStore } from "@/store";
import moment from "moment";
import { Vue, Component, Ref, Watch } from "vue-property-decorator";
import EventPreview from "@/components/events/preview.vue";
import EventOngoing from "@/components/events/ongoing.vue";
moment.locale("zh-tw");
enum EventScope {
  ONGOING = "收集中",
  END = "已結束",
}
enum FieldScope {
  EVENTS = "EVENTS",
  LANDING = "LANDING",
}
export interface Event {
  id: number;
  isBeforeStart: boolean;
  isOnGoing: boolean;
  isJustEnd: boolean;
  isTrueEnd: boolean;
  landingPageUrl: string;
  giftImageUrl: string;
  giftName: string;
  giftCount: string;
  currentRank: string;
  endAt: string;
  mostContributorAvatarUrl: string;
  mostContributorName: string;
}
Vue.prototype.EventScope = EventScope;
Vue.prototype.FieldScope = FieldScope;
@Component({
  components: {
    EventPreview,
    EventOngoing,
  },
})
export default class Events extends Vue {
  currentEventScope: EventScope = EventScope.ONGOING;
  banners: {
    name: string;
    imageUrl: string;
    linkUrl: string | null;
  }[] = [];
  events: Event[] = [];
  landingPage: {
    url: string;
  } = {
    url: "",
  };
  currentFieldScope: FieldScope = FieldScope.EVENTS;
  @Ref("event-iframe") eventIframe!: HTMLIFrameElement;
  isIframeReady = false;

  get scopedEvents(): Event[] {
    switch (this.currentEventScope) {
      case EventScope.ONGOING:
        return this.events.filter(
          (e) => e.isOnGoing || e.isJustEnd || e.isBeforeStart
        );
      case EventScope.END:
        return this.events.filter((e) => e.isTrueEnd);
      default:
        return [];
    }
  }

  get accessToken(): null | string {
    return liveStore.accessToken;
  }

  mounted(): void {
    this.init();
    window.addEventListener("message", this.setValueMessageEvent, false);
  }
  beforeDestroy(): void {
    window.removeEventListener("message", this.setValueMessageEvent);
  }
  setValueMessageEvent(ev: MessageEvent): void {
    if (ev.data.action === 'ready') {
      this.isIframeReady = true
    }
  }

  @Watch("currentFieldScope")
  @Watch("eventIframe")
  @Watch("isIframeReady")
  @Watch("accessToken", {immediate: true})
  onEventIframeChanged(): void {
    if (this.currentFieldScope === FieldScope.LANDING && this.isIframeReady) {
      this.setAuthToken()
    }
  }

  setAuthToken(): void {
    if (!this.eventIframe) return
    if (!this.eventIframe.contentWindow) return

    this.eventIframe.contentWindow.postMessage(
      {
        action: 'sendAccessToken',
        payload: this.accessToken,
      },
      this.landingPage.url,
    )
  }

  close(): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.$modal.hide(this.$parent.name);
  }
  async init(): Promise<void> {
    if (window.targetLandingPage) {
      this.showLandingPage(window.targetLandingPage);
      window.targetLandingPage = undefined;
    }
    await Promise.all([this.fetchBanners(), this.fetchEvents()]);
  }
  async fetchBanners(): Promise<void> {
    this.banners = await (
      await clientApi
        .getActivityBannerList()
        .then((res) => res.getBannerInfoList())
    ).map((e) => {
      return {
        name: e.getName(),
        imageUrl: e.getImageUrl(),
        linkUrl: e.getLinkUrl(),
      };
    });
  }
  async fetchEvents(): Promise<void> {
    this.events = await (
      await clientApi
        .getActivityList(undefined, Number(liveStore.currentLadyUid))
        .then((res) => res.getInfoList())
    ).map((e, index) => {
      const currentTimestamp = Date.now();
      const startTimestamp = (e.getStartAt()?.getSeconds() || 0) * 1000;
      const endTimestamp = (e.getEndAt()?.getSeconds() || 0) * 1000;
      const isBeforeStart = currentTimestamp <= startTimestamp;
      const isAfterStart = currentTimestamp > startTimestamp;
      const isBeforeEnd = currentTimestamp <= endTimestamp;
      const isAfterEnd = currentTimestamp > endTimestamp;
      const isTrueEnd = moment().diff(endTimestamp, "days") > 3 && isAfterEnd;
      const isJustAfterEnd =
        moment().diff(endTimestamp, "days") <= 3 && isAfterEnd;
      let endAt = "";
      if (isJustAfterEnd) {
        endAt = "剛剛結束";
      } else if (isAfterEnd) {
        endAt = "已結束";
      } else {
        endAt = `還有 ${moment(endTimestamp).fromNow(true)}`;
      }

      const landingPageUrl = new URL(e.getActivityLinkUrl())
      const newSearch = new URLSearchParams(landingPageUrl.search)
      newSearch.append('t', `${Date.now()}`)
      landingPageUrl.search = newSearch.toString()
      // console.debug('landingPageUrl', landingPageUrl.toString())
      
      return {
        id: index,
        isBeforeStart: isBeforeStart,
        isOnGoing: isAfterStart && isBeforeEnd,
        isJustEnd: isJustAfterEnd,
        isTrueEnd: isTrueEnd,
        landingPageUrl: landingPageUrl.toString(),
        giftImageUrl: e.getGiftImageUrl(),
        giftName: e.getGiftName(),
        giftNumber: e.getAmount(),
        giftCount:
          e.getAmount() > 0
            ? `${utilityApi.numberWithCommas(e.getAmount())} 已收集`
            : "尚未獲得",
        currentRank: e.getLadyRank(),
        endAt: endAt,
        mostContributorAvatarUrl: e.getTopFanAvatar(),
        mostContributorName: e.getTopFanName(),
      };
    });
  }

  showLandingPage(url: string): void {
    if (url) {
      this.isIframeReady = false
      this.currentFieldScope = FieldScope.LANDING;
      this.landingPage.url = url;
      this.scrollToTop();
    }
  }
  showNormalScope(): void {
    this.currentFieldScope = FieldScope.EVENTS;
    this.landingPage.url = "";
    this.scrollToTop();
  }
  scrollToTop(): void {
    const eventArea = document.getElementById("event-container");
    eventArea?.scrollTo(0, 0);
  }
}
