import { isGrpcDevtoolsAvailable, enableGrpcDevtools } from '@/lib/grpc-tool/grpc-devtools'

const noop = () => void 0

declare let __GRPCWEB_DEVTOOLS__: ((_clients: any[]) => void) | undefined

export const enableGrpcWebDevtools: Exclude<
  typeof __GRPCWEB_DEVTOOLS__,
  undefined
> =
  localStorage.getItem('gubed') === 'true'
    ? typeof __GRPCWEB_DEVTOOLS__ === 'function'
      ? __GRPCWEB_DEVTOOLS__
      : isGrpcDevtoolsAvailable
        ? enableGrpcDevtools
        : noop
    : noop

if (localStorage.getItem('gubed') === 'true') {
  localStorage.setItem('dogdebug', 'true')
} else {
  localStorage.removeItem('dogdebug')
}
