
























































import { liveStore } from "@/store";
import { Vue, Component } from "vue-property-decorator";
import moment from "moment";
import Noty from "noty";
import copy from "@/lib/copy_to_clipboard";
import postMessageBackToFaceProfile from "@/helpers/post-message-back-to-face-profile";
import postMessageFollowFace from "@/helpers/post-message-follow-face"; 

@Component({})
export default class Usercard extends Vue {
  isShowMoreMenu = false;
  debugGiftId = ''

  get humanLiveStatus(): string {
    return liveStore.humanLiveStatus;
  }

  get isLive(): boolean {
    return liveStore.isLive;
  }

  get streamerAvatarUrl(): string {
    return liveStore.streamerAvatarUrl;
  }

  get streamerName(): string {
    return liveStore.streamerName;
  }

  get audienceCount(): number {
    return liveStore.audiencesCount;
  }

  get startAt(): string {
    if (liveStore.roomDetail) {
      return `直播時間：${moment(
        liveStore.roomDetail.data.detail.start_time
      ).format("MM/DD HH:mm")}`;
    } else {
      return "";
    }
  }

  get bannerUrl(): string {
    return `https://jkface.net/profile/${liveStore.currentLadyUid}`;
  }

  get isLogin(): boolean {
    return liveStore.isLogin;
  }

  get debug(): boolean {
    return liveStore.DEBUG;
  }

  get isMeHost(): boolean {
    return liveStore.isMeHost;
  }

  get isFollowed(): boolean {
    return liveStore.isFollowed;
  }

  follow(): void {
    postMessageFollowFace(!this.isFollowed)
    liveStore._isFollowed(!this.isFollowed)
  }

  copyLink(): void {
    const ladyUid = liveStore.roomDetail?.data.anchor.user.open_id;
    copy(`https://jkface.net/live/${ladyUid}`);
    new Noty({ text: "複製連結成功" }).show();
  }

  toggleMoreMenu(): void {
    this.isShowMoreMenu = !this.isShowMoreMenu;
  }

  startPrivate() {
    this.$ws.sendWebsocketMsg("start_private", {
      private: {
        start: Date.now() / 1000 + 60,
        boundary_id: 1,
        ticket: 1,
      },
    });
  }
  stopPrivate() {
    this.$ws.sendWebsocketMsg("end_private", {
      private: {
        end: Date.now(),
      },
    });
  }
  closeLive() {
    this.$ws.sendWebsocketMsg("close_live", {});
  }
  toggleEndCover() {
    liveStore._isShowEndCover(!liveStore.isShowEndCover);
  }
  togglePrivateCover() {
    liveStore._isShowPrivateCover(!liveStore.isShowPrivateCover);
  }
  toggleInPrivate() {
    liveStore._isInPrivate(!liveStore.isInPrivate);
  }
  showEvents(): void {
    this.$modal.show("events-modal");
  }
  postMessageBackToFaceProfile(): void {
    postMessageBackToFaceProfile()
  }
  showGiftAnimator():void {
    if (!this.debugGiftId.trim()) return

    liveStore.setDebugShowGiftAnimator({
      giftId: parseInt(this.debugGiftId.replace(/[^\d]/g, ''), 10)
    })
  }
}
