










































import { liveStore } from "@/store";
import { Vue, Component, Prop } from "vue-property-decorator";
import throttle from "lodash.throttle";
import { utilityApi } from "@/lib/util";
import Donate from "./donate.vue";
@Component({
  methods: {
    sendMessage: throttle(function () {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      if (that.isInputValid && !that.isComposing) {
        that.isSending = true;
        that.$ws.sendWebsocketMsg("text", {
          toUser: "all",
          text: that.inputText,
        });
        that.inputText = "";
        that.isSending = false;
      }
    }, 300),
  },
})
export default class ChatInput extends Vue {
  inputText = "";
  isSending = false;
  isComposing = false;
  get isInputValid(): boolean {
    return (
      !/^\s*$/.test(this.inputText) &&
      !this.isSending &&
      this.isInputLengthValid &&
      !this.isBanned
    );
  }
  maxInputCount = 60;
  get inputCount(): number {
    return this.inputText.length;
  }
  get isInputLengthValid(): boolean {
    return this.inputCount <= this.maxInputCount;
  }
  get isBanned(): boolean {
    return liveStore.bannedNahuoIds.includes(
      String(liveStore.liveDetail?.userInfo.user_id)
    );
  }
  showDonate(): void {
    const isMobile = liveStore.isMobile;
    if (isMobile) {
      const modalHeight = window.innerHeight * 0.7;
      this.$modal.show(
        Donate,
        {
          showHead: false,
        },
        {
          classes: "bottom-attached-modal live-module",
          adaptive: true,
          height: modalHeight,
        }
      );
    } else {
      liveStore._isShowDonate(true);
    }
  }
}
