








import { Vue, Component, Prop } from "vue-property-decorator";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import IOdometer from "vue-odometer";
import "odometer/themes/odometer-theme-default.css";
@Component({
  components: {
    IOdometer,
  },
})
export default class Odometer extends Vue {
  @Prop() val!: number;
  // odometer
  counterCurrentAmount = 0;
  odometerDuration = 1000;
  mounted() {
    const val = this.val;
    setTimeout(() => {
      this.counterCurrentAmount = val;
    }, 200);
  }
}
