





























/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Downloader, EVENT_TYPES, Parser, Player } from "svga.lite";
import { WsGiftMessage } from "@/lib/ws";
import { liveStore } from "@/store";
import type { DebugShowGiftAnimator } from "@/store/live";

interface GiftMessagge extends WsGiftMessage {
  svgaUrl: string;
}
@Component({})
export default class Animator extends Vue {
  messageStack: GiftMessagge[] = [];
  isPlaying = false;
  senderName = "senderName";
  senderAvatarUrl = "";
  giftName = "giftName";
  giftAmount = 1;

  async mounted() {
    this.initWebsocket();
  }
  initWebsocket() {
    this.$ws.addEventListener("giftEnter", this.receiveGift);
  }

  get debugShowGiftAnimator(): DebugShowGiftAnimator {
    return liveStore.debugShowGiftAnimator;
  }
  @Watch("debugShowGiftAnimator")
  onDebugShowGiftAnimatorChange(val: DebugShowGiftAnimator): void {
    if (val.giftId === null) return;
    if (this.isPlaying) return;
  
    this.showGiftAnimator(val.giftId)
  }
  async showGiftAnimator(giftId:DebugShowGiftAnimator['giftId']):Promise<void> {
    const targetGift = liveStore.gifts.find((e) => e.gift_id === giftId);
    if (targetGift === undefined) return
    if (this.isPlaying) return

    try{
      this.isPlaying = true

      this.giftName = targetGift.gift_name;
      this.senderName = 'debug測試';
      await this.playSvga({
        svgaUrl: targetGift.jkfAnimationUrl,
        times:1,
      })
    } catch(err){
      console.debug(err)
    } finally{
      liveStore.setDebugShowGiftAnimator({
        giftId: null
      })
      this.isPlaying = false
    }
  }

  async playSvga(params: { svgaUrl: GiftMessagge['svgaUrl'], times: number } ): Promise<void> {
    const downloader = new Downloader();
      // 默认调用 WebWorker 线程解析
      // 可配置 new Parser({ disableWorker: true }) 禁止
      const parser = new Parser();
      // #canvas 是 HTMLCanvasElement
      const player = new Player("#svga-canvas");
      const fileData = await downloader.get(params.svgaUrl);
      const svgaData = await parser.do(fileData);

      // @ts-ignore
      player.set({ loop: params.times });

      await player.mount(svgaData);

      player
        // @ts-ignore
        .$on("start", () => {
          const el = document.getElementById("animator") as HTMLDivElement;
          el.style.zIndex = "3";
          el.style.display = "flex";
        })
        // .$on("pause", () => console.log("event pause"))
        // .$on("stop", () => console.log("event stop"))
        // @ts-ignore
        .$on("end", () => {
          const el = document.getElementById("animator") as HTMLDivElement;
          el.style.zIndex = "-1";
          el.style.display = "none";
          player.clear();
        })
        // @ts-ignore
        .$on("clear", () => {
          this.isPlaying = false;
        });
      // 动画播放中事件回调
      // .$on("process", () => console.log("event process", player.progress));

      player.start();

      // player.pause()

      // player.stop()

      // player.clear()

  }

  receiveGift(e: MessageEvent): void {
    let message: GiftMessagge = JSON.parse(e.data);
    const targetGift = liveStore.gifts.find((e) => e.gift_id === message.tipid);
    let playTimes = 1;
    if (
      targetGift &&
      targetGift.animationType === "svga" &&
      !liveStore.isShowPrivateCover
    ) {
      message.svgaUrl = targetGift.jkfAnimationUrl;
      this.senderName = message.nickname;
      this.senderAvatarUrl = message.avatar;
      this.giftName = message.tipname;
      this.giftAmount = message.num;
      this.messageStack.push(message);
      const giftCount = message.num;
      if (giftCount >= 100) {
        playTimes = 5;
      } else if (giftCount >= 50) {
        playTimes = 4;
      } else if (giftCount >= 30) {
        playTimes = 3;
      } else if (giftCount >= 10) {
        playTimes = 2;
      }
    }

    if (!this.isPlaying) {
      this.play(playTimes);
    }
  }
  async play(times = 1): Promise<void> {
    const message: GiftMessagge | undefined = this.messageStack.shift();
    this.isPlaying = true;
    if (message) {
      await this.playSvga({
        svgaUrl: message.svgaUrl,
        times,
      })
    } else {
      this.isPlaying = false;
    }

    if (this.messageStack.length > 0) {
      this.play();
    }
  }
}
