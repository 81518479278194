

























































import { RankAudience } from "@/lib/api";
import { liveStore } from "@/store";
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})
export default class Rank extends Vue {
  get onlineAudiences(): RankAudience[] {
    return liveStore.onlineAudiences;
  }
  get donatedAudiences(): RankAudience[] {
    return liveStore.onlineAudiences.filter(
      (e) => parseInt(e.donate_amount) > 0
    );
  }
  get top3Audiences(): RankAudience[] {
    return this.donatedAudiences.slice(0, 3);
  }
  get restAudiences(): RankAudience[] {
    return this.donatedAudiences.slice(4, this.donatedAudiences.length);
  }
  get undonatedAudiences(): RankAudience[] {
    return liveStore.onlineAudiences.filter(
      (e) => parseInt(e.donate_amount) === 0
    );
  }
  close(): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.$modal.hide(this.$parent.name);
  }
  nthAudience(index: number): RankAudience | null {
    return this.onlineAudiences[index];
  }
}
