















































import { liveStore } from "@/store";
import { Vue, Component, Prop } from "vue-property-decorator";
import VModal from "vue-js-modal";
import { RankAudience } from "@/lib/api";
import { utilityApi } from "@/lib/util";
import RankVue from "@/components/rank.vue";
import  postMessageBackToFaceProfile from "@/helpers/post-message-back-to-face-profile";

Vue.use(VModal);
@Component({})
export default class EndCover extends Vue {
  get streamerName(): string {
    return liveStore.streamerName;
  }

  get streamerAvatarUrl(): string {
    return liveStore.streamerAvatarUrl;
  }

  get humanLiveStatus(): string {
    return liveStore.humanLiveStatus;
  }

  get isLive(): boolean {
    return liveStore.isLive;
  }

  get donatedAudiences(): RankAudience[] {
    return liveStore.onlineAudiences.filter(
      (e) => parseInt(e.donate_amount) > 0
    );
  }
  get top3Audiences(): RankAudience[] {
    return this.donatedAudiences.slice(0, 3);
  }
  get holderCount(): number {
    const num = 3 - this.top3Audiences.length;
    return num > 0 ? num : 0;
  }
  get isMobile(): boolean {
    return liveStore.isMobile;
  }
  showRank(): void {
    const modalHeight = window.innerHeight * 0.7;
    if (this.isMobile) {
      this.$modal.show(
        RankVue,
        {},
        {
          classes: "bottom-attached-modal live-module",
          adaptive: true,
          height: modalHeight,
        }
      );
    } else {
      this.$modal.show(
        RankVue,
        {},
        {
          classes: "live-module",
          styles: `border-radius: 10px;`,
          adaptive: true,
          height: modalHeight,
        }
      );
    }
  }
  postMessageBackToFaceProfile(): void {
    postMessageBackToFaceProfile()
  }
}
