var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chatroom"},[_c('div',{staticClass:"chatroom-head"},[_vm._v("即時聊天室")]),_c('div',{staticClass:"chatroom-body"},[_c('div',{staticClass:"chat-logs",attrs:{"id":"chat-logs"}},[_vm._l((_vm.chats),function(chat){return _c('div',{key:chat.msgid,staticClass:"chat-log"},[(chat.fromUser === 'system')?[_c('div',{staticClass:"chat-log__main"},[_c('div',{staticClass:"chat-log__main__message"},[(chat.fromUser === 'system' && chat.gradeweight)?_c('div',{staticClass:"chat-log__main__message__prefix",class:("chat-log__main__message__prefix--" + (chat.gradeweight))},[_vm._v(" Lv."+_vm._s(chat.gradeweight)+" ")]):_vm._e(),_c('div',{staticClass:"chat-log__main__message__content"},[_vm._v(" "+_vm._s(chat.content)+" ")])])])]:[_c('div',{staticClass:"chat-log__user"},[_c('div',{staticClass:"chat-log__user__avatar"},[_c('img',{directives:[{name:"avatar",rawName:"v-avatar"}],attrs:{"src":chat.avatar}})])]),_c('div',{staticClass:"chat-log__main"},[_c('div',{staticClass:"chat-log__main__message"},[_c('div',{staticClass:"chat-log__main__message__sender-name",class:{
                  'chat-log__main__message__sender-name--modable':
                    (_vm.isMeModerator && !_vm.isMod(chat) && !_vm.isHost(chat)) ||
                    (_vm.isMeHost && !_vm.isHost(chat)),
                  'chat-log__main__message__sender-name--mod': _vm.isMod(chat),
                  'chat-log__main__message__sender-name--host': _vm.isHost(chat),
                },on:{"click":function($event){return _vm.showModeratorMenu($event, chat)}}},[_vm._v(" "+_vm._s(_vm.displayName(chat.nickname, _vm.isMod(chat), _vm.isHost(chat)))+" ")]),_c('div',{staticClass:"chat-log__main__message__content"},[_vm._v(" "+_vm._s(chat.content)+" ")])])])]],2)}),_c('ModMenu',{attrs:{"chat":_vm.currentModMenuTarget}})],2),(_vm.isError)?_c('div',{staticClass:"error-hint"},[_vm._v("聊天室出現問題 請稍後再試")]):_vm._e(),_c('div',{staticClass:"auto-scroll-button",class:{ 'auto-scroll-button--hide': _vm.isAutoScrolling },staticStyle:{"word-break":"keep-all","color":"white"},on:{"click":_vm.scrollToBottom}},[(_vm.debug)?_c('span',[_vm._v(_vm._s(_vm.chatStack.length)+"條新訊息")]):_vm._e()])]),_c('div',{staticClass:"chatroom-foot"},[_c('div',{staticClass:"send-message"},[_c('div',{staticClass:"send-message__current-user"},[_c('div',{staticClass:"send-message__current-user__avatar"},[_c('img',{directives:[{name:"avatar",rawName:"v-avatar"}],attrs:{"src":_vm.currentUser.avatar}})])]),_c('div',{staticClass:"send-message__input-field"},[_c('div',{staticClass:"send-message__input-field__you"},[_vm._v(" "+_vm._s(_vm.currentUser.name)+" ")]),(_vm.isBanned)?_c('textarea',{staticClass:"\n            send-message__input-field__input\n            send-message__input-field__input--disabled\n          ",attrs:{"placeholder":"[你已被禁言]","rows":"1","disabled":""}}):(!_vm.isLive)?_c('textarea',{staticClass:"\n            send-message__input-field__input\n            send-message__input-field__input--disabled\n          ",attrs:{"placeholder":"[直播已結束]","rows":"1","disabled":""}}):_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputText),expression:"inputText"}],staticClass:"send-message__input-field__input",class:{
            'send-message__input-field__input--disabled':
              _vm.inputText.length > _vm.CONSTANT_MAX_INPUT_COUNT,
          },attrs:{"id":"comment-textarea","rows":"1","placeholder":"說點什麼...","spellcheck":"false"},domProps:{"value":(_vm.inputText)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.sendMessage.apply(null, arguments)},"compositionstart":function($event){_vm.isComposing = true},"compositionend":function($event){_vm.isComposing = false},"input":function($event){if($event.target.composing){ return; }_vm.inputText=$event.target.value}}}),_vm._v(" "),_c('div',{staticClass:"send-message__input-field__action"},[(_vm.isLive)?_c('button',{staticClass:"send-gift__send-button live-module-button",on:{"click":_vm.showDonate}},[_vm._v(" 送禮 ")]):_c('button',{staticClass:"\n              send-gift__send-button send-gift__send-button--disabled\n              live-module-button\n            "},[_vm._v(" 送禮 ")]),_c('div',{staticClass:"send-message__input-field__string-count",class:{
              'send-message__input-field__string-count--alert':
                _vm.inputText.length > _vm.CONSTANT_MAX_INPUT_COUNT,
            }},[_vm._v(" "+_vm._s(_vm.inputText.length)+"/"+_vm._s(_vm.CONSTANT_MAX_INPUT_COUNT)+" ")]),_c('div',{staticClass:"send-message__input-field__send-button",class:{
              'send-message__input-field__send-button--disabled':
                !_vm.isInputValid,
            },on:{"click":_vm.sendMessage}})])])])]),(_vm.isShowDonate)?_c('Donate',{on:{"showDonate":_vm.showDonate}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }