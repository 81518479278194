




















































import { BuyTicketRes, clientApi } from "@/lib/api";
import { liveStore } from "@/store";
import type { Ga4Params } from "@/store/live";
import { Vue, Component } from "vue-property-decorator";
import Noty from "noty";
import postMessageBuyDiamond from "@/helpers/post-message-buy-diamond";
import postMessageDealGiftSuccess from "@/helpers/post-message-deal-gift-success";

const PRIVATE_TICKET_UNIT_PRICE = process.env.VUE_APP_PRIVATE_TICKET_UNIT_PRICE;
const PRIVATE_PERIOD_IN_MINUTES = process.env.VUE_APP_PRIVATE_PERIOD_IN_MINUTES;

@Component({
  name: "purchase",
})
export default class Purchase extends Vue {
  isReady = false;
  isLoading = false;
  isAffordable = false;
  PRIVATE_PERIOD_IN_MINUTES = PRIVATE_PERIOD_IN_MINUTES;

  get accessToken(): string {
    return liveStore.accessToken || "";
  }
  get unitPrice(): number {
    return PRIVATE_TICKET_UNIT_PRICE * liveStore.privateModeUnitTickets;
  }
  get isShowShortTimeHint(): boolean {
    return liveStore.isShowShortTimeHint;
  }
  get ga4ClientId(): string {
    return liveStore.ga4ClientId || "";
  }
  get ga4Params(): Ga4Params {
    return liveStore.ga4Params;
  }
  async mounted(): Promise<void> {
    this.isReady = false;
    await this.init();
    this.isReady = true;
    window.addEventListener("message", this.refresh, false);
  }
  beforeDestroy(): void {
    window.removeEventListener("message", this.refresh, false);
  }
  async refresh(ev: MessageEvent): Promise<void> {
   if (ev.data?.action === "isDiamondDealSuccess") {
     if (ev.data?.payload) {
      new Noty({
        text: "儲值完成",
      }).show();
     } else  {
      new Noty({
        text: "儲值失敗請重試",
      }).show();
     }
      await this.init();
      this.resetModalWidth();
   }
  }
  resetModalWidth(): void {
    const container: HTMLDivElement | null =
      document.querySelector(".purchase-modal");
    if (container) {
      container.classList.remove("purchase-modal--with-charge");
    }
  }
  async init(): Promise<void> {
    try {
      this.isLoading = true;
      const currentDiamonds = await clientApi.getDiamondAmount(
        this.accessToken
      );
      this.isAffordable = currentDiamonds >= this.unitPrice;
      this.isLoading = false;
    } catch (error) {
      console.log(error);
      new Noty({ text: `無法取得鑽石餘額，請稍候再試` }).show();
      this.close();
    }
  }
  charge(): void {
    postMessageBuyDiamond()
  }
  async purchase(): Promise<void> {
    if (
      liveStore.roomDetail &&
      liveStore.nahuoToken &&
      liveStore.accessToken &&
      !this.isLoading
    ) {
      this.isLoading = true;
      const result: BuyTicketRes = await clientApi.buyTicket({
        liveId: liveStore.roomDetail?.data.detail.live_id,
        nahuoToken: liveStore.nahuoToken,
        accessToken: liveStore.accessToken,
        ga4ClientId: this.ga4ClientId,
        ga4Params: this.ga4Params,
      });
      if (result.code === 1) {
        postMessageDealGiftSuccess()
        new Noty({ text: "購買房卡成功" }).show();
        liveStore.checkCanGetAgoraToken({
          liveId: liveStore.liveId,
          nahuoToken: liveStore.nahuoToken,
          syncPrivateCover: true,
        });
        liveStore._isShowPrivateModeForecast(false);
        liveStore._isConfirmedContinuousBuyingPrivateTicket(true);
        this.close();
        this.$ws.sendWebsocketMsg("giftEnter", {
          toUser: "all",
          tipid: result.data.tipid,
          tipimg: result.data.tipimg,
          tipname: result.data.tipname,
          money: result.data.money,
          num: result.data.num,
        });
      } else if (
        result.code === -30 ||
        result.code === -40 ||
        result.code === -50
      ) {
        new Noty({ text: result.msg }).show();
        liveStore.checkCanGetAgoraToken({
          liveId: liveStore.liveId,
          nahuoToken: liveStore.nahuoToken,
          syncPrivateCover: true,
        });
        liveStore._isShowPrivateModeForecast(false);
        this.close();
      } else {
        new Noty({ text: result.msg }).show();
      }
    }
    this.isLoading = false;
  }
  close(): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.$modal.hide(this.$parent.name);
  }
}
