















import { clientApi } from "@/lib/api";
import { liveStore } from "@/store";
import { Vue, Component, Ref } from "vue-property-decorator";
interface animationData {
  id: number;
  timer: number;
  opacity: number;
  pathDatas: pathData[];
  image: string[];
  imageIndex: number;
  factor: factor;
}
interface pathData {
  x: number;
  y: number;
}
interface factor {
  speed: number;
  t: number;
}
@Component({})
export default class HeartBubble extends Vue {
  queue: { [key: string]: animationData } = {};
  timer = 0;
  @Ref("heart-bubble-canvas") readonly heartCanvas!: HTMLCanvasElement;
  ctx: null | CanvasRenderingContext2D = null;
  heartImages = [
    "https://public.hare200.com/jkflive/hearts/1.png",
    "https://public.hare200.com/jkflive/hearts/2.png",
    "https://public.hare200.com/jkflive/hearts/3.png",
    "https://public.hare200.com/jkflive/hearts/4.png",
    "https://public.hare200.com/jkflive/hearts/5.png",
  ];
  activityImages = [
    "https://public.hare200.com/jkflive/2021_love_christmas/snowflake.png",
    "https://public.hare200.com/jkflive/2021_love_christmas/snowflake.png",
    "https://public.hare200.com/jkflive/2021_love_christmas/snowflake.png",
    "https://public.hare200.com/jkflive/2021_love_christmas/snowflake.png",
    "https://public.hare200.com/jkflive/2021_love_christmas/snowflake.png",
  ];
  get isOnActivity(): boolean {
    return liveStore.isOnActivity;
  }
  get currentImages(): string[] {
    return this.heartImages
  }

  mounted(): void {
    if (this.heartCanvas) {
      this.ctx = this.heartCanvas.getContext("2d");
    }
    this.$ws.addEventListener("like", () => {
      this.pushAnimationStack();
    });
  }
  likeClick(): void {
    this.$ws.sendWebsocketMsg("like", {
      toUser: "all",
    });

    liveStore.getIsOnActivity()
    if (this.isOnActivity) {
      clientApi.sendJkfaceActivityFreeItem(
        liveStore.nahuoToken || "",
        liveStore.liveId,
        liveStore.liveDetail?.anchor.anchor_id || 0,
        liveStore.accessToken || ""
      );
    }
  }

  pushAnimationStack(): void {
    const anmationData: animationData = {
      id: new Date().getTime(),
      timer: 0,
      opacity: 0,
      pathDatas: this.generatePathDatas(),
      image: this.currentImages,
      imageIndex: Math.floor(Math.random() * (4 - 0 + 1)) + 0,
      factor: {
        speed: 0.01, // 运动速度，值越小越慢
        t: 0, //  贝塞尔函数系数
      },
    };
    if (Object.keys(this.queue).length > 0) {
      this.queue[anmationData.id] = anmationData;
    } else {
      this.queue[anmationData.id] = anmationData;
      this.bubbleAnimate();
    }
  }
  generatePathDatas(): pathData[] {
    const p0 = {
      x: 25,
      y: 400,
    };
    const p1 = {
      x: this.getRandom(20, 30),
      y: this.getRandom(200, 300),
    };
    const p2 = {
      x: this.getRandom(0, 80),
      y: this.getRandom(100, 200),
    };
    const p3 = {
      x: this.getRandom(0, 80),
      y: this.getRandom(0, 50),
    };
    return [p0, p1, p2, p3];
  }
  getRandom(min: number, max: number): number {
    return Math.random() * (max - min) + min;
  }
  bubbleAnimate(): void {
    if (this.ctx) {
      this.ctx.clearRect(0, 0, this.heartCanvas.width, this.heartCanvas.height);
      Object.keys(this.queue).forEach((key) => {
        const anmationData = this.queue[+key];
        const { x, y } = this.updatePath(
          anmationData.pathDatas,
          anmationData.factor
        );
        const speed = anmationData.factor.speed;
        anmationData.factor.t += speed;
        var theImage = document.createElement("img");
        theImage.src = this.currentImages[anmationData.imageIndex];
        if (this.ctx) {
          this.ctx.drawImage(theImage, x, y, 30, 30);
        }
        if (anmationData.factor.t > 1) {
          delete this.queue[anmationData.id];
        }
      });
      if (Object.keys(this.queue).length > 0) {
        this.timer = setTimeout(() => {
          this.bubbleAnimate();
        }, 20);
      } else {
        clearTimeout(this.timer);
        // this.ctx.draw(); // 清空画面
        if (this.heartCanvas) {
          this.ctx.clearRect(
            0,
            0,
            this.heartCanvas.width,
            this.heartCanvas.height
          );
        }
      }
    }
  }
  updatePath(data: pathData[], factor: factor): { x: number; y: number } {
    const p0 = data[0]; // 三阶贝塞尔曲线起点坐标值
    const p1 = data[1]; // 三阶贝塞尔曲线第一个控制点坐标值
    const p2 = data[2]; // 三阶贝塞尔曲线第二个控制点坐标值
    const p3 = data[3]; // 三阶贝塞尔曲线终点坐标值

    const t = factor.t;

    /*计算多项式系数*/
    const cx1 = 3 * (p1.x - p0.x);
    const bx1 = 3 * (p2.x - p1.x) - cx1;
    const ax1 = p3.x - p0.x - cx1 - bx1;

    const cy1 = 3 * (p1.y - p0.y);
    const by1 = 3 * (p2.y - p1.y) - cy1;
    const ay1 = p3.y - p0.y - cy1 - by1;

    /*计算xt yt的值 */
    const x = ax1 * (t * t * t) + bx1 * (t * t) + cx1 * t + p0.x;
    const y = ay1 * (t * t * t) + by1 * (t * t) + cy1 * t + p0.y;
    return {
      x,
      y,
    };
  }
}
