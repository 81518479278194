import Vue from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'

Vue.config.productionTip = false
const onReturn = function () {
  console.log("============")
  console.log("onReturn ...123")
  console.log("============")
}

new Vue({
  router,
  store,
  render: function (createElement) {
    return createElement(App, {
      props: {
        onReturn: onReturn
      },
    })
  }
}).$mount('#app')
