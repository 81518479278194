























import { liveStore } from "@/store";
import { Vue, Component, Prop } from "vue-property-decorator";
import Purchase from "@/components/purchase.vue";
import VModal from "vue-js-modal";
import { utilityApi } from "@/lib/util";
import postMessageBackToFaceProfile from "@/helpers/post-message-back-to-face-profile";
import postMessageLogin from'@/helpers/post-message-login'

Vue.use(VModal);
@Component({})
export default class PrivateCover extends Vue {
  get streamerName(): string {
    return liveStore.streamerName;
  }

  get streamerAvatarUrl(): string {
    return liveStore.streamerAvatarUrl;
  }

  get humanLiveStatus(): string {
    return liveStore.humanLiveStatus;
  }

  get isLive(): boolean {
    return liveStore.isLive;
  }

  purchase(): void {
    if (liveStore.isLogin) {
      this.$modal.show(
        Purchase,
        {},
        {
          classes: "purchase-modal live-module",
          styles: `border-radius: 5px;max-width: 100vw;`,
          adaptive: true,
          height: "auto",
          width: 350,
        }
      );
    } else {
      postMessageLogin()
    }
  }
  postMessageBackToFaceProfile(): void {
    postMessageBackToFaceProfile()
  }
}
