


























































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import Video from "@/components/video.vue";
import Chatroom from "@/components/chatroom.vue";
import ChatroomGuest from "@/components/chatroom_guest.vue";
import Usercard from "@/components/usercard.vue";
import Control from "@/components/control.vue";
import BackToTop from "@/components/back_to_top.vue";
import { liveStore } from "@/store";
import Ws, { ModWsResponse } from "@/lib/ws";
import VModal from "vue-js-modal";
import Noty from "noty";
import { ConnectedMessage } from "@/store/live";
import { utilityApi } from "@/lib/util";
import EventsVue from "@/components/events/events.vue";
// TODO
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
Vue.use(VModal);

declare module "vue/types/vue" {
  interface Vue {
    $ws: Ws;
  }
}

Vue.directive("avatar", {
  bind: function (el, binding, vnode) {
    el.onerror = function () {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.src = process.env.VUE_APP_FALLBACK_AVATAR;
    };
  },
});

Noty.overrideDefaults({
  layout: "bottomCenter",
  timeout: 5000,
  type: "warning",
});

declare global {
  interface Window {
    targetLandingPage: string | undefined;
  }
}

@Component({
  name: "Live",
  components: {
    Video,
    Usercard,
    Chatroom,
    ChatroomGuest,
    Control,
    BackToTop,
    EventsVue,
  },
})
export default class Live extends Vue {
  @Prop({ type: String, required: true }) uid!: string;
  @Prop({ type: Function }) onReturn!: () => void;
  get isMobile(): boolean {
    return liveStore.isMobile;
  }
  get isReady(): boolean {
    return liveStore.isInitReady;
  }
  get isLogin(): boolean {
    return liveStore.isLogin;
  }
  get eventsModalHeight(): number {
    return window.innerHeight;
  }
  get isInIframe(): boolean {
    try {
      return window.self !== window.top;
    } catch (error) {
      return true;
    }
  }
  @Watch("isMobile")
  onIsMobileChanged(val: boolean): void {
    const isMobile = val;
    this.setVideoSection(isMobile);
  }
  @Watch("isReady")
  onIsReadyChanged(): void {
    this.$nextTick(() => {
      if (this.isReady) {
        this.setVideoSection(this.isMobile);
      }
    })
  }

  async beforeMount(): Promise<void> {
    this.init();
  }
  async init(): Promise<void> {
    // TODO
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.liveStore = liveStore;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.liveVue = this;

    const ws = new Ws();
    Vue.prototype.$ws = ws;

    const uid = this.uid;
    liveStore.setCurrentLadyUid(uid);
    liveStore.detectMobile();
    await liveStore.init(uid);
    liveStore._ws(ws);

    ws.addEventListener("mod", this.receiveModMessage);
    ws.addEventListener("setMod", this.receiveModMessage);
    ws.addEventListener("connected", (e: MessageEvent) => {
      const message: ConnectedMessage = JSON.parse(e.data);
      if (message.manage === "3") {
        new Noty({
          text: `您已被設為副播`,
        }).show();
      }
    });
    ws.addEventListener("end_private", (e: MessageEvent) => {
      new Noty({ text: "私密直播已結束" }).show();
    });
    //
    this.$root.$on("return", () => {
      this.onReturn();
    });
  }
  receiveModMessage(e: MessageEvent): void {
    const message: ModWsResponse = JSON.parse(e.data);
    const myId = liveStore.currentUser.nahuoUid;
    if (
      myId == message.bannedMid ||
      myId == message.kickMid ||
      myId == message.manageMid
    ) {
      switch (message.type) {
        case "banned":
          if (message.banned === 0) {
            new Noty({
              text: `您已被解除禁言`,
            }).show();
          } else if (message.banned === 1) {
            new Noty({
              text: `您已被禁言`,
            }).show();
          }
          break;
        case "kick":
          if (message.kick === 0) {
            new Noty({
              text: `您已被解除踢出`,
            }).show();
          } else if (message.kick === 1) {
            new Noty({
              text: `您已經被請出房間`,
            }).show();
          }
          break;
        case "setManage":
          if (message.manage === 0) {
            new Noty({
              text: `您已被解除副播`,
            }).show();
          } else if (message.manage === 3) {
            new Noty({
              text: `您已被設為副播`,
            }).show();
          }
          break;
        default:
          break;
      }
    }
  }
  doReturn(): void {
    this.$root.$emit("return");
  }
  setVideoSection(isMobile: boolean): void {
    const videoSection = document.querySelector(
        ".video-section"
      ) as HTMLDivElement;
    if (!videoSection) return

    if (isMobile) {
      if (videoSection.style.width) {
        videoSection.style.removeProperty("width");
      }
      videoSection.style.height = `${window.innerHeight}px`;
    } else {
      videoSection.style.width = `${(videoSection.clientHeight * 9) / 16}px`;
      if (videoSection.style.height) {
        videoSection.style.removeProperty("height");
      }
    }
  }
}
