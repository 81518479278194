/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { liveStore } from "@/store"
const HEART_BEAT_INTERVAL_IN_SECONDS = 5

/* eslint-disable @typescript-eslint/ban-types */
export default class Ws {
  url = process.env.VUE_APP_WS_HOST
  conn: WebSocket | null = null
  callbacks: { [index: string]: Function[] } = {}
  heartBeater: number | null = null
  loginBeater: number | null = null
  reconnectTimer: number | null = null
  isError = false
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.myws = this
  }

  get status(): number {
    if (this.conn) {
      // 0	CONNECTING	Socket has been created. The connection is not yet open.
      // 1	OPEN	The connection is open and ready to communicate.
      // 2	CLOSING	The connection is in the process of closing.
      // 3	CLOSED	The connection is closed or couldn't be opened.
      return this.conn.readyState
    }
    return -1
  }

  connect(url: string = this.url) {
    // this.url = url
    if (this.conn) {
      console.log('ws connected already')
    } else {
      const conn = new WebSocket(url)
      console.log('ws connecting...')
      conn.onerror = (event: Event) => {
        this.isError = true
        console.log("ws error: ", event.type, event)
      }

      conn.onopen = (event: Event) => {
        console.log("socke連接成功: ", event.type, event)
        this.conn = conn
        this.sendWebsocketMsg("login", { toUser: "system" })
        this.heartBeater = setInterval(() => {
          this.sendWebsocketMsg('communication', {
            toUser: 'system'
          })
        }, HEART_BEAT_INTERVAL_IN_SECONDS * 1000)
        this.reconnectTimer = setTimeout(() => {
          this.reconnect()
        }, HEART_BEAT_INTERVAL_IN_SECONDS * 2 * 1000)
      }
      conn.onclose = (event: CloseEvent) => {
        console.log("socket斷線: ", event.type, event)
        if (this.conn) {
          this.conn = null
        }
        if (this.heartBeater) {
          clearInterval(this.heartBeater)
        }
        if (this.loginBeater) {
          clearInterval(this.loginBeater)
        }
        this.reconnectTimer && clearTimeout(this.reconnectTimer)
        setTimeout(() => {
          this.reconnect()
        }, 3000);
      }
      conn.onmessage = (event: MessageEvent) => {
        const type = JSON.parse(event.data)["type"]
        if (type === 'text') {
          this.callbacks['chat'] && this.callbacks['chat'].forEach(func => { func(event) })
          this.callbacks['floatchat'] && this.callbacks['floatchat'].forEach(func => { func(event) })
        }
        if (type === 'connected') {
          this.callbacks['connected'] && this.callbacks['connected'].forEach(func => { func(event) })
        }
        if (type === 'giftEnter') {
          this.callbacks['gift'] && this.callbacks['gift'].forEach(func => { func(event) })
          this.callbacks['floatGift'] && this.callbacks['floatGift'].forEach(func => { func(event) })
          this.callbacks['giftEnter'] && this.callbacks['giftEnter'].forEach(func => { func(event) })
        }
        if (type === 'setting') {
          this.callbacks['setting'] && this.callbacks['setting'].forEach(func => { func(event) })
        }
        if (type === 'banned' || type === "kick") {
          this.callbacks['mod'] && this.callbacks['mod'].forEach(func => { func(event) })
        }
        if (type === 'setManage') {
          this.callbacks['setMod'] && this.callbacks['setMod'].forEach(func => { func(event) })
        }
        if (type === 'userEnter') {
          this.callbacks['userEnter'] && this.callbacks['userEnter'].forEach(func => { func(event) })
        }
        if (type === 'userLeave') {
          this.callbacks['userLeave'] && this.callbacks['userLeave'].forEach(func => { func(event) })
        }
        if (type === 'start_private') {
          this.callbacks['start_private'] && this.callbacks['start_private'].forEach(func => { func(event) })
        }
        if (type === 'end_private') {
          this.callbacks['end_private'] && this.callbacks['end_private'].forEach(func => { func(event) })
        }
        if (type === 'close_live') {
          this.callbacks['close_live'] && this.callbacks['close_live'].forEach(func => { func(event) })
        }
        if (type === 'like') {
          this.callbacks['like'] && this.callbacks['like'].forEach(func => { func(event) })
        }
        if (type === 'debug') {
          this.callbacks['debug'] && this.callbacks['debug'].forEach(func => { func(event) })
        }
        if (type === "communication") {
          this.reconnectTimer && clearTimeout(this.reconnectTimer)
          this.reconnectTimer = setTimeout(() => {
            this.reconnect()
          }, HEART_BEAT_INTERVAL_IN_SECONDS * 2 * 1000)
        }
      }
    }
  }

  reconnect() {
    this.reconnectTimer && clearTimeout(this.reconnectTimer)
    this.disconnect()
    this.connect(this.url)
  }

  disconnect() {
    const conn = this.conn
    if (conn) {
      conn.close()
      console.log('disconnected')
    }
  }

  addEventListener(name: string, func: Function) {
    if (!this.callbacks[name]) {
      this.callbacks[name] = []
    }
    this.callbacks[name].push(func)
  }

  removeEventListener(name: string, func: Function) {
    if (this.callbacks[name]) {
      const index = this.callbacks[name].findIndex(o => o === func)
      if (index !== -1) {
        this.callbacks[name].splice(index, 1)
      }
    }
  }

  sendWebsocketMsg(type: string, obj: { [key: string]: any }) {
    const detail = liveStore.liveDetail
    if (detail && this.conn) {
      //用户会话类型
      obj.type = type;
      //用户通讯标识
      obj.scene = 'chat';
      //视频客服ID
      obj.liveid = detail.detail.live_id;
      //指定客户ID
      obj.uniacid = process.env.VUE_APP_WXAPP_ID;
      //用户头像
      obj.avatar = (type == 'communication' || type == 'like') ? '' : detail.userInfo.avatarUrl,
        //用户token
        obj.token = detail.wsConfig.token;
      //用户random
      obj.random = detail.wsConfig.random;
      //用户ID
      obj.mid = detail.userInfo.user_id;
      // if (detail.userInfo.grade != null) {
      //     //用户等级和权重
      //     obj.gradename = detail.userInfo.grade.name;
      //     obj.gradeweight = detail.userInfo.grade.weight;
      // }
      //用户昵称
      obj.nickname = detail.userInfo.nickName;

      this.conn.send(JSON.stringify(obj));
      return;
    }
  }

  sendRoleWebsocketMsg(type: string, role: number, obj: { [key: string]: any }) {
    obj.role = role
    this.sendWebsocketMsg(type, obj)
  }

  toggleBan(payload: {
    // 0: 解除, 1: 禁言
    banned: number,
    // 對象nahuo uid
    bannedMid: string,
    // 對象nickname
    bannedNick: string,
    bannedAvatar: string,
    bannedRole: number
  }) {
    this.sendRoleWebsocketMsg("banned", payload.bannedRole, payload)
  }

  toggleKick(payload: {
    // 0: 解除, 1: 踢出
    kick: number,
    // 對象nahuo uid
    kickMid: string,
    // 對象nickname
    kickNick: string,
    kickAvatar: string,
    kickRole: number
  }) {
    this.sendRoleWebsocketMsg("kick", payload.kickRole, payload)
  }

  toggleModerator(payload: {
    // 3: 副播, 0: 解除
    manage: number;
    // 對象nahuo uid
    manageMid: string;
    // 對象nickname
    manageNick: string;
    manageAvatar: string;
    manageRole: number
  }) {
    this.sendRoleWebsocketMsg("setManage", payload.manageRole, payload)
  }
}
export interface ModWsResponse {
  type: "banned" | "kick" | "setManage";
  // 執行動作的user id
  fromUser: number;
  // 0: 解除, 1: 禁言/踢出
  banned?: number;
  kick?: number;
  // 0: 觀眾, 3: 副播
  manage?: number
  // 被ban/kick user id
  bannedMid?: string | number;
  kickMid?: string | number;
  manageMid?: string | number;
  toUser: "allext";
  liveid: number;
}

export interface WsGiftMessage {
  type: "giftEnter";
  fromUser: number;
  toUser: "all";
  nickname: string;
  avatar: string;
  tipid: number;
  tipname: string;
  tipimg: string;
  money: number;
  num: number;
  liveid: number;
  is_ticket: boolean;
  donate_amount: string;
}

export interface WsUserEnterMessage {
  fd: string;
  ip: string;
  province: string;
  city: string;
  district: string;
  mid: number;
  nickname: string;
  gradename: string;
  gradeweight: number;
  avatar: string;
  liveid: number;
  role: string;
  lasttime: number;
  online: number;
  manage: string;
  is_virt: string;
  lb0: string;
  lb0c: string;
  lb1: string;
  lb1c: string;
  banned: string;
  kick: string;
  localip: string;
  onlinetime: string;
  logindev: number;
  type: "userEnter";
  onlinenum: number;
  usercount: number;
  rusercount: number;
  reload: number;
  level: number;
  level_name: string;
}

export interface WsUserLeaveMessage {
  type: "userLeave";
  fromUser: string;
  toUser: string;
  nickname: string;
  liveid: number;
  usercount: number;
  rusercount: number;
  onlinenum: number;
}

export interface WsEndPrivateMessage {
  type: "end_private"
}

export interface WsStartPrivateMessage {
  type: "start_private",
  private: {
    start: number;
    boundary_id: number;
    ticket: number;
  }
}

export interface WsHeartMessage {
  type: "like";
  avatar: string;
  fromUser: number;
  likecount: number;
  liveid: number;
  nickname: string;
  toUser: "all"
}

export interface WsDebugMessage {
  type: "debug";
  msg: string;
  debug_id: string
}

