import { render, staticRenderFns } from "./back_to_top.vue?vue&type=template&id=07ad8509&scoped=true&"
import script from "./back_to_top.vue?vue&type=script&lang=ts&"
export * from "./back_to_top.vue?vue&type=script&lang=ts&"
import style0 from "./back_to_top.vue?vue&type=style&index=0&id=07ad8509&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07ad8509",
  null
  
)

export default component.exports