/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import Vue, { ComponentOptions, AsyncComponent } from "vue"
import { ApiLoginResponse, RankAudience, Chat, clientApi, getGiftListResponse, Gift, LiveDetail, UserType, RoomDetailResponse } from '@/lib/api'
import AgoraRTC, { IAgoraRTCRemoteUser, IRemoteAudioTrack, IRemoteVideoTrack } from 'agora-rtc-sdk-ng'
import Cookies from 'js-cookie'
import Ws, { ModWsResponse, WsGiftMessage, WsStartPrivateMessage, WsUserEnterMessage, WsUserLeaveMessage } from '@/lib/ws'
import { liveStore } from '.'
import moment from "moment"
import { utilityApi } from '@/lib/util'
import { debounce } from 'lodash'
import postMessageKickOut from "@/helpers/post-message-kick-out"

clientApi.setJkfHost('PAN', process.env.VUE_APP_MEMBER_API_HOST);
clientApi.setJkfHost('ACTIVITY', process.env.VUE_APP_DONATE_API_HOST);

const ACCESS_TOKEN_REFRESH_PERIOD_IN_SECONDS = 540
const POLLING_ROOMDETAIL_INTERVAL_IN_SECONDS = 5


// TODO
AgoraRTC.setLogLevel(4)
export interface CurrentUser {
    nahuoUid: number;
    jkfUid: number;
    name: string;
    avatar: string;
}
export interface ConnectedMessage {
    banned: string;
    fromUser: string;
    hotgoods: string;
    kick: string;
    likecount: number;
    liveid: number;
    manage: string;
    onlinenum: number;
    rusercount: number;
    settings: Setting;
    toUser: string;
    type: "connected";
    usercount: number;
}
export interface Setting {
    bannedall: number | string;
    canat: number | string;
    canrepeal: number | string;
    gonggao: string;
    kefu: string;
    likevirtual: number | string;
    status: number | string;
    virtual: number | string;
    virtualadd: number | string;
}
export enum LiveStatus {
    // "1" = "直播中",
    live = 1,
    // "2" = "暫停中",
    pause = 2,
    // "3" = "回放中",
    playback = 3,
    // "4" = "已結束"
    over = 4
}

export type Ga4Params = {
    sessionId: string,
    pageTitle: string,
    pagePath: string,
}

export type DebugShowGiftAnimator = Record<'giftId', Gift['gift_id'] | null>
@Module({
    name: 'Live',
    stateFactory: true,
    namespaced: true,
})
export default class LiveModule extends VuexModule {
    authToken: string | null = null
    accessToken: string | null = null
    tokenUpdaterId: number | null = null
    nahuoToken: string | null = null
    ga4ClientId: string | null = null
    ga4Params: Ga4Params = {
        sessionId: '',
        pageTitle: '',
        pagePath: '',
    }
    currentUser: CurrentUser = {
        nahuoUid: 0,
        jkfUid: 0,
        name: "YOU",
        avatar: ""
    }
    isLogin = false
    isMeModerator = false
    isMeHost = false
    // Live
    ws: Ws | null = null
    liveId = 0
    currentLadyUid = "-1"
    currentLiveStatus: LiveStatus = LiveStatus.over
    liveDetail: LiveDetail | null = null
    settings: Setting | null = null
    announcement: string | null = null
    roomDetail: RoomDetailResponse | null = null
    backgroundImageUrl = ""

    agoraClient = AgoraRTC.createClient({ mode: "live", codec: "vp8" });
    audioRemoteUser: IAgoraRTCRemoteUser | null = null
    videoRemoteUser: IAgoraRTCRemoteUser | null = null
    audioTracks: IRemoteAudioTrack[] = []
    videoTracks: IRemoteVideoTrack[] = []

    isFollowed = false
    chats: Chat[] = []
    onlineAudiences: RankAudience[] = []
    audiencesCount = 0
    gifts: Gift[] = []

    moderatorNahuoIds: string[] = []
    bannedNahuoIds: string[] = []
    KickedNahuoIds: string[] = []

    isShowPrivateModeForecast = false
    isShowPrivateCover = false
    isShowPurchaseModal = false
    isShowShortTimeHint = false
    privateModeTansitionInSeconds = 0
    privateModeUnitTickets = 0
    privateStartTime = -1
    canGetAgoraToken = false
    isConfirmedContinuousBuyingPrivateTicket = false
    renewTokenTrigger = 0
    isShowEndCover = false
    isShowDonate = false
    isInPrivate = false
    isClearMode = false
    isMobile = false
    isOnActivity = false
    // Status
    isInitReady = false
    pollingTimer: number | null = null

    // debug
    DEBUG = localStorage.getItem('livedebug') === 'true'
    debugShowGiftAnimator: DebugShowGiftAnimator = {
        giftId: null
    }

    get streamerName(): string {
        return this.roomDetail?.data.anchor.anchor_name || 'streamer'
    }

    get streamerAvatarUrl(): string {
        return this.roomDetail?.data.anchor.anchor_avatar || ''
    }

    get humanLiveStatus(): string {
        const currentStatus = this.currentLiveStatus;
        switch (currentStatus) {
            case LiveStatus.live:
                return "直播中";
            case LiveStatus.pause:
                return "直播房";
            case LiveStatus.playback:
                return "直播房";
            case LiveStatus.over:
                return "直播房";
            default:
                return "直播房";
        }
    }

    get isLive(): boolean {
        return this.currentLiveStatus === LiveStatus.live;
    }

    @Action
    getIsOnActivity(): void {
        const startDate = new Date('2024-09-01T10:00:00+08:00')
        const endDate = new Date('2024-09-28T23:59:59+08:00')
        const result =  moment().unix() > moment(startDate).unix() && moment().unix() < moment(endDate).unix()
        this._isOnActivity(result)
    }

    @Action
    async init(jkfUid: string): Promise<void> {
        async function pollRoomDetail(jkfUid: string) {
            liveStore.setPollingTimer(setTimeout(async () => {
                const result = await clientApi.getRoomDetail(jkfUid).then(res => res.data.detail.live_status === LiveStatus.live)
                if (result) {
                    await liveStore.init(jkfUid)
                    liveStore.ws?.sendWebsocketMsg("login", { toUser: "system" })
                } else {
                    pollRoomDetail(jkfUid)
                }
            }, POLLING_ROOMDETAIL_INTERVAL_IN_SECONDS * 1000))
        }

        this._isInitReady(false)
        const isLiving: boolean = await this.getRoomDetail(jkfUid).then(res => res.data.detail.live_status === LiveStatus.live)
        const authToken = this.authToken
        if (authToken) {
            this._isLogin(true)
            const accessToken = await this.getAccessToken(authToken)
            const nahuoToken = await this.nahuoLogin(accessToken)

            this._tokenUpdater(
                setInterval(async () => {
                    const theAuthToken = this.authToken
                    if (theAuthToken) {
                        try {
                            const newAccessToken = await this.getAccessToken(theAuthToken)
                            await this.nahuoLogin(newAccessToken)
                        } catch(e) {
                            console.debug(e)
                        }
                    } else if (this.tokenUpdaterId) {
                        this.clearTokenUpdaterId()
                    }
                }, ACCESS_TOKEN_REFRESH_PERIOD_IN_SECONDS * 1000)
            )
            await this.getLiveDetail(nahuoToken)
            await this.checkCanGetAgoraToken({ liveId: this.liveId, nahuoToken: nahuoToken, syncPrivateCover: true })
            await this.getChats(nahuoToken)
            this.getGiftList(nahuoToken)
            this.getTaggedAudiences({ liveId: this.liveId, nahuoToken: nahuoToken })
        } else {
            this._isLogin(false)
            await this.checkCanGetAgoraToken({ liveId: this.liveId, syncPrivateCover: true })
            this.getAudiences()
        }
        if (!isLiving) {
            pollRoomDetail(jkfUid)
        }
        this._setGa4ClientId(Cookies.get('_ga') ?? '')

        this._isInitReady(true)
    }

    @Action({ commit: "_accessToken" })
    async getAccessToken(authToken: string): Promise<string> {
        return await clientApi.getAccessToken(authToken).then(
            (res) => {
                return res.getAccessToken()
            }
        );
    }

    @Action({ commit: "_currentUser" })
    getCurrentUser(res: ApiLoginResponse): CurrentUser {
        return {
            nahuoUid: res.data.user_id.user_id,
            jkfUid: parseInt(res.data.user_info.open_id),
            name: res.data.user_info.nickName,
            avatar: res.data.user_info.avatarUrl
        }
    }

    @Action
    async follow(payload: { nahuoUid: number, nahuoToken: string }): Promise<void> {
        const isFollowed: boolean = await clientApi.nahuoFollow(payload.nahuoUid, payload.nahuoToken).then(res => res.code === 1)
        this._isFollowed(isFollowed)
    }

    @Action({ commit: "_onlineAudiences" })
    async getAudiences(payload?: { page?: number; pageSize?: number }) {
        const originalAudiences: RankAudience[] = await clientApi.getLiveUserRankList(this.liveId, payload?.page, payload?.pageSize)
        return originalAudiences.filter(e => e.manage !== "1").sort((a, b) => parseInt(b.donate_amount) - parseInt(a.donate_amount))
    }

    @Action({ commit: "_gifts" })
    async getGiftList(nahuoToken: string): Promise<Gift[]> {
        const res: getGiftListResponse = await clientApi.getGiftList(nahuoToken)
        return res.data.list
    }

    @Action
    gotKicked(): void {
        localStorage.setItem("live_got_kicked", String(this.roomDetail?.data.detail.live_id))
        postMessageKickOut()
    }

    @Action({ commit: "_roomDetail" })
    async getRoomDetail(uid: string): Promise<RoomDetailResponse> {
        const res = await clientApi.getRoomDetail(uid)
        this._liveId(res.data.detail.live_id)
        this._backgroundImageUrl(res.data.detail.background_image)
        this._currentLiveStatus(res.data.detail.live_status)
        if (res.data.detail.private_time) {
            this.startPrivateMode({
                startAt: res.data.detail.private_time.start,
                tickets: res.data.detail.private_time.ticket,
                showForecast: false,
                showShortTimeHint: true
            })
        }
        this._isShowEndCover(res.data.detail.live_status === LiveStatus.over)
        return res
    }

    @Action
    async getTaggedAudiences(payload: {
        liveId: number;
        nahuoToken: string;
    }): Promise<void> {
        const modIds: string[] = await clientApi.getAudienceList(payload.liveId, payload.nahuoToken, UserType.MOD).then(res => res.map(e => e.mid))
        const kickIds: string[] = await clientApi.getAudienceList(payload.liveId, payload.nahuoToken, UserType.KICK).then(res => res.map(e => e.mid))
        const bannedIds: string[] = await clientApi.getAudienceList(payload.liveId, payload.nahuoToken, UserType.BAN).then(res => res.map(e => e.mid))
        this.setModeratorNahuoIds(modIds)
        this.setBannedNahuoIds(bannedIds)
        this.setKickedNahuoIds(kickIds)
    }

    @Action({ commit: "_canGetAgoraToken" })
    async checkCanGetAgoraToken(payload: {
        liveId: number;
        nahuoToken?: string;
        syncPrivateCover?: boolean;
    }) {
        const result: { code: number; msg: string; } = await clientApi.canGetAgoraToken(payload.liveId, payload.nahuoToken)
        if (payload.syncPrivateCover && result.code === -20) {
            // -20: 需要購買門票
            this._isShowPrivateCover(true)
        }
        return result.code === 1
    }

    @Action({ commit: "_nahuoToken" })
    async nahuoLogin(accessToken: string): Promise<string> {
        const apiLoginRes = await clientApi.nahuoApiLogin(accessToken);
        this.getCurrentUser(apiLoginRes)
        return apiLoginRes.data.token
    }

    @Action({ commit: "_liveDetail" })
    async getLiveDetail(nahuoToken: string): Promise<LiveDetail> {
        const liveDetail = await clientApi.getLiveDetail(
            this.liveId,
            Date.now(),
            nahuoToken
        );
        this._isMeHost(liveDetail.anchor.user_id == liveDetail.userInfo.user_id ? true : false)
        return liveDetail
    }

    @Action({ commit: "_chats" })
    async getChats(nahuoToken: string): Promise<Chat[]> {
        if (this.liveDetail) {
            const chats = await clientApi.getChats(this.liveDetail.detail.live_id, nahuoToken).then(res => res.data)
            return chats
        } else {
            return []
        }
    }

    @Mutation _liveDetail(liveDetail: LiveDetail) { this.liveDetail = liveDetail }
    @Mutation _liveId(id: number) { this.liveId = id }
    @Mutation _accessToken(token: string) { this.accessToken = token }
    @Mutation _tokenUpdater(id: number) { this.tokenUpdaterId = id }
    @Mutation _setGa4ClientId(ga4ClientId: string) { this.ga4ClientId = ga4ClientId }
    @Mutation _setGa4Params(ga4Params: Ga4Params) { this.ga4Params = ga4Params }
    @Mutation clearTokenUpdaterId() {
        if (this.tokenUpdaterId) {
            clearInterval(this.tokenUpdaterId)
            this.tokenUpdaterId = null
        }
    }
    @Mutation _audioRemoteUser(user: IAgoraRTCRemoteUser | null) { this.audioRemoteUser = user }
    @Mutation _videoRemoteUser(user: IAgoraRTCRemoteUser | null) { this.videoRemoteUser = user }
    @Mutation _isFollowed(bool: boolean) { this.isFollowed = bool }
    @Mutation _isOnActivity(bool: boolean) { this.isOnActivity = bool }
    @Mutation _currentUser(user: CurrentUser) { this.currentUser = user }
    @Mutation _nahuoToken(token: string) { this.nahuoToken = token }
    @Mutation _isInitReady(bool: boolean) { this.isInitReady = bool }
    @Mutation _chats(chats: Chat[]) { this.chats = chats }
    @Mutation pushChat(chat: Chat) { this.chats.push(chat) }
    @Mutation _settings(payload: Setting) { this.settings = payload }
    @Mutation _isMeHost(bool: boolean) { this.isMeHost = bool }
    @Mutation _ws(ws: Ws) {
        ws.addEventListener("connected", (e: MessageEvent) => {
            const message: ConnectedMessage = JSON.parse(e.data);
            liveStore._settings(message.settings);
            liveStore._isMeModerator(message.manage === "3");
            if (message.kick === "1") {
                liveStore.gotKicked()
            }
            liveStore.getAudiences()
            liveStore._audiencesCount(message.usercount)
            liveStore._announcement(message.settings.gonggao)
        });
        ws.addEventListener("setting", (e: MessageEvent) => {
            const message: { settings: Setting } = JSON.parse(e.data);
            liveStore._settings(message.settings);
            liveStore._announcement(message.settings.gonggao)
        });
        ws.addEventListener("setMod", (e: MessageEvent) => {
            const msg: ModWsResponse = JSON.parse(e.data);
            const isMe = msg.manageMid == liveStore.liveDetail?.userInfo.user_id;
            if (msg.manage === 0) {
                liveStore.deleteModeratorNahuoId(String(msg.manageMid));
                isMe && liveStore._isMeModerator(false);
            } else if (msg.manage === 3) {
                liveStore.pushModeratorNahuoId(String(msg.manageMid));
                isMe && liveStore._isMeModerator(true);
            }
        });
        ws.addEventListener("userEnter", (e: MessageEvent) => {
            const msg: WsUserEnterMessage = JSON.parse(e.data)
            liveStore._audiencesCount(msg.usercount)
            liveStore.getAudiences()
        });
        ws.addEventListener("userLeave", (e: MessageEvent) => {
            const msg: WsUserLeaveMessage = JSON.parse(e.data)
            liveStore._audiencesCount(msg.usercount)
            // liveStore.deleteAudience(parseInt(msg.fromUser))
            liveStore.getAudiences()
        });
        ws.addEventListener("mod", (e: MessageEvent) => {
            // 踢房檢查
            const msg: ModWsResponse = JSON.parse(e.data)
            if (msg.type === "kick" && msg.kickMid == liveStore.liveDetail?.userInfo.user_id) {
                liveStore.gotKicked()
            }
        });
        ws.addEventListener("start_private", (e: MessageEvent) => {
            // 開始私密直播檢查
            const msg: WsStartPrivateMessage = JSON.parse(e.data)
            liveStore.startPrivateMode({
                startAt: msg.private.start,
                tickets: msg.private.ticket,
                showForecast: true,
                showShortTimeHint: false
            })
        });
        ws.addEventListener("end_private", (e: MessageEvent) => {
            // 結束私密直播檢查
            liveStore.stopPrivateMode()
        });
        ws.addEventListener("giftEnter", (e: MessageEvent) => {
            const msg: WsGiftMessage = JSON.parse(e.data)
            liveStore.modifyUserDonateAmount({ id: String(msg.fromUser), amount: msg.donate_amount })
        });
        ws.addEventListener("close_live", (e: MessageEvent) => {
            liveStore.stopPrivateMode()
            liveStore._isShowEndCover(true)
            liveStore._currentLiveStatus(LiveStatus.over)
        });
        ws.disconnect();
        ws.connect();
        this.ws = ws
    }

    @Mutation _isLogin(bool: boolean) { this.isLogin = bool }
    @Mutation _onlineAudiences(audiences: RankAudience[]) { this.onlineAudiences = audiences }
    @Mutation
    mutateAudience(newAudience: RankAudience) {
        let audience = this.onlineAudiences.find(e => e.mid === newAudience.mid)
        if (audience) {
            audience = newAudience
        }
    }
    @Mutation
    pushAudience(audience: RankAudience) {
        if (!this.onlineAudiences.find(e => e.mid === audience.mid)) {
            this.onlineAudiences.push(audience)
        }
    }
    @Mutation
    deleteAudience(id: number) {
        const index = this.onlineAudiences.findIndex(e => e.mid === String(id))
        this.onlineAudiences.splice(index, 1)
    }
    @Mutation setModeratorNahuoIds(ids: string[]) { this.moderatorNahuoIds = ids }
    @Mutation
    pushModeratorNahuoId(id: string) {
        const index: number = this.moderatorNahuoIds.indexOf(id)
        if (index === -1) {
            this.moderatorNahuoIds.push(id)
        }
    }
    @Mutation
    deleteModeratorNahuoId(id: string) {
        const index: number = this.moderatorNahuoIds.indexOf(id)
        if (index > -1) {
            this.moderatorNahuoIds.splice(index, 1)
        }
    }
    @Mutation setBannedNahuoIds(ids: string[]) { this.bannedNahuoIds = ids }
    @Mutation
    pushBannedNahuoId(id: string) {
        const index: number = this.bannedNahuoIds.indexOf(id)
        if (index === -1) {
            this.bannedNahuoIds.push(id)
        }
    }
    @Mutation
    deleteBannedNahuoId(id: string) {
        const index: number = this.bannedNahuoIds.indexOf(id)
        if (index > -1) {
            this.bannedNahuoIds.splice(index, 1)
        }
    }
    @Mutation setKickedNahuoIds(ids: string[]) { this.KickedNahuoIds = ids }
    @Mutation
    pushKickedNahuoId(id: string) {
        const index: number = this.KickedNahuoIds.indexOf(id)
        if (index === -1) {
            this.KickedNahuoIds.push(id)
        }
    }
    @Mutation
    deleteKickedNahuoId(id: string) {
        const index: number = this.KickedNahuoIds.indexOf(id)
        if (index > -1) {
            this.KickedNahuoIds.splice(index, 1)
        }
    }
    @Mutation _gifts(gifts: Gift[]) { this.gifts = gifts }
    @Mutation _isMeModerator(bool: boolean) { this.isMeModerator = bool }
    @Mutation _backgroundImageUrl(url: string) { this.backgroundImageUrl = url }
    @Mutation _roomDetail(detail: RoomDetailResponse) { this.roomDetail = detail }
    @Mutation startPrivateMode(payload: {
        startAt: number;
        tickets: number;
        showForecast: boolean;
        showShortTimeHint: boolean;
    }) {
        this.isShowPrivateModeForecast = payload.showForecast
        this.isShowShortTimeHint = payload.showShortTimeHint
        this.privateStartTime = payload.startAt
        this.privateModeTansitionInSeconds = payload.startAt - Date.now() / 1000;
        this.privateModeUnitTickets = payload.tickets
    }

    @Mutation stopPrivateMode() {
        this.isShowPrivateModeForecast = false
        this.isShowPrivateCover = false
        this.privateModeUnitTickets = 0
        this.renewTokenTrigger = this.renewTokenTrigger + 1
    }

    @Mutation togglePurchaseModal(bool?: boolean) {
        if (bool !== undefined) {
            this.isShowPurchaseModal = bool
        } else {
            this.isShowPurchaseModal = !this.isShowPurchaseModal
        }
    }

    @Mutation
    _canGetAgoraToken(bool: boolean) { this.canGetAgoraToken = bool }
    @Mutation
    _isShowPrivateCover(bool: boolean) { this.isShowPrivateCover = bool }
    @Mutation
    _isShowPrivateModeForecast(bool: boolean) { this.isShowPrivateModeForecast = bool }
    @Mutation
    _isShowEndCover(bool: boolean) { this.isShowEndCover = bool }
    @Mutation _isShowDonate(bool: boolean) { this.isShowDonate = bool }
    @Mutation _isConfirmedContinuousBuyingPrivateTicket(bool: boolean) { this.isConfirmedContinuousBuyingPrivateTicket = bool }
    @Mutation _currentLiveStatus(status: LiveStatus) { this.currentLiveStatus = status }
    @Mutation pushAudioTrack(track: IRemoteAudioTrack) { this.audioTracks.push(track) }
    @Mutation pushVideoTrack(track: IRemoteVideoTrack) { this.videoTracks.push(track) }
    @Mutation initAudioTrack() { this.audioTracks = []}
    @Mutation initVideoTrack() { this.videoTracks = []}
    @Mutation modifyUserDonateAmount(payload: { id: string, amount: string }) {
        const targetAudience = this.onlineAudiences.find(e => e.mid === payload.id)
        if (targetAudience) {
            targetAudience.donate_amount = payload.amount
        }
    }
    @Mutation setPollingTimer(timer: number) {
        if (this.pollingTimer) {
            clearTimeout(this.pollingTimer)
        }
        this.pollingTimer = timer
    }
    @Mutation setCurrentLadyUid(uid: string) { this.currentLadyUid = uid }
    @Mutation _audiencesCount(num: number) { this.audiencesCount = num }
    @Mutation _isInPrivate(bool: boolean) { this.isInPrivate = bool }
    @Mutation _announcement(text: string) { this.announcement = text }
    @Mutation _isClearMode(bool: boolean) { this.isClearMode = bool }
    @Mutation detectMobile() {
        this.isMobile = utilityApi.isMobile()
        window.addEventListener("resize", debounce(() => {
            console.log("resize...")
            this.isMobile = utilityApi.isMobile()
        }, 100))
    }

    @Mutation _authToken(token: string) {
        this.authToken = token
    }
    @Action({ commit: "_authToken" })
    async setAuthToken(token: string) {
        return token
    }

    @Mutation setDebugShowGiftAnimator(value: DebugShowGiftAnimator) {
        this.debugShowGiftAnimator = value
    }
}

