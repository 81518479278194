import type { StreamInterceptor, UnaryInterceptor } from 'grpc-web'

declare const __gRPCDevtools__:
  | undefined
  | {
      gRPCDevtoolsUnaryInterceptor: UnaryInterceptor<unknown, unknown>
      gRPCDevtoolsStreamInterceptor: StreamInterceptor<unknown, unknown>
    }

const gRPCDevtoolsUnaryInterceptor =
  typeof __gRPCDevtools__ === 'object' &&
  __gRPCDevtools__ !== null &&
  __gRPCDevtools__.gRPCDevtoolsUnaryInterceptor
const gRPCDevtoolsStreamInterceptor =
  typeof __gRPCDevtools__ === 'object' &&
  __gRPCDevtools__ !== null &&
  __gRPCDevtools__.gRPCDevtoolsStreamInterceptor
export const isGrpcDevtoolsAvailable =
  !!gRPCDevtoolsUnaryInterceptor && !!gRPCDevtoolsStreamInterceptor
const unaryInterceptors = isGrpcDevtoolsAvailable
  ? [gRPCDevtoolsUnaryInterceptor]
  : []
const streamInterceptors = isGrpcDevtoolsAvailable
  ? [gRPCDevtoolsStreamInterceptor]
  : []
export const enableGrpcDevtools = (clients: any[]) => {
  clients.forEach((client: any) => {
    // FIXME: 實際上不應使用 `b`, `g` 註冊 interceptors，如果官方有提供全域註冊的方式再更改，或者各自在 gRPC 的 service constructor 註冊
    // b: streamInterceptors
    // g: unaryInterceptors
    try {
      client.client_.b = streamInterceptors
      client.client_.g = unaryInterceptors
    } catch {
      // ignore
    }
  })
}
