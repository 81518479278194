













































import { Vue, Component, Prop } from "vue-property-decorator";
import { ModWsResponse } from "@/lib/ws";
import { Chat } from "@/lib/api";
import { liveStore } from "@/store";
@Component({})
export default class ModMenu extends Vue {
  @Prop() chat!: Chat;
  get isShow(): boolean {
    return parseInt(this.chat?.msgid) > 0;
  }
  get isModerator(): boolean {
    if (this.chat) {
      return liveStore.moderatorNahuoIds.includes(String(this.chat.fromUser));
    } else {
      return false;
    }
  }
  get isBanned(): boolean {
    if (this.chat) {
      return liveStore.bannedNahuoIds.includes(String(this.chat.fromUser));
    } else {
      return false;
    }
  }
  get isKicked(): boolean {
    if (this.chat) {
      return liveStore.KickedNahuoIds.includes(String(this.chat.fromUser));
    } else {
      return false;
    }
  }
  get isMeHost(): boolean {
    return liveStore.isMeHost;
  }
  get isMeModerator(): boolean {
    return liveStore.isMeModerator;
  }
  ban(bool: boolean): void {
    this.$ws.toggleBan({
      banned: bool ? 1 : 0,
      bannedMid: this.chat.fromUser,
      bannedNick: this.chat.nickname,
      bannedAvatar: this.chat.avatar,
      bannedRole: liveStore.liveDetail?.userInfo.role || -1,
    });
  }
  setModerator(bool: boolean): void {
    this.$ws.toggleModerator({
      manage: bool ? 3 : 0,
      manageMid: this.chat.fromUser,
      manageNick: this.chat.nickname,
      manageAvatar: this.chat.avatar,
      manageRole: liveStore.liveDetail?.userInfo.role || -1,
    });
  }
  kick(bool: boolean): void {
    this.$ws.toggleKick({
      kick: bool ? 1 : 0,
      kickMid: this.chat.fromUser,
      kickNick: this.chat.nickname,
      kickAvatar: this.chat.avatar,
      kickRole: liveStore.liveDetail?.userInfo.role || -1,
    });
  }
  mounted(): void {
    this.initWebsocket();
  }
  initWebsocket(): void {
    this.$ws.addEventListener("mod", this.receiveModMessage);
    this.$ws.addEventListener("setMod", this.receiveModMessage);
  }

  receiveModMessage(e: MessageEvent): void {
    let message: ModWsResponse = JSON.parse(e.data);

    switch (message.type) {
      case "banned":
        if (message.banned === 0) {
          liveStore.deleteBannedNahuoId(String(message.bannedMid));
        } else if (message.banned === 1) {
          liveStore.pushBannedNahuoId(String(message.bannedMid));
        }
        break;
      case "kick":
        if (message.kick === 0) {
          liveStore.deleteKickedNahuoId(String(message.kickMid));
        } else if (message.kick === 1) {
          liveStore.pushKickedNahuoId(String(message.kickMid));
        }
        break;
      case "setManage":
        if (message.manage === 0) {
          liveStore.deleteModeratorNahuoId(String(message.manageMid));
        } else if (message.manage === 3) {
          liveStore.pushModeratorNahuoId(String(message.manageMid));
        }
        break;
      default:
        break;
    }
  }
}
