
import { enableGrpcWebDevtools } from '@/lib/grpc-tool/enable-grpc-web-devtools'

import { MemberServicePromiseClient } from '@lctech-tw/jkface-proto/dist/js/face-front-api/member_grpc_web_pb'
import {
  GetMemberInfoReq,
} from '@lctech-tw/jkface-proto/dist/js/face-front-api/member_pb'

const memberClient = (() => {
  const _memberClient = new MemberServicePromiseClient(
    process.env.VUE_APP_FACE_API_HOST,
  )
  enableGrpcWebDevtools([_memberClient])
  return _memberClient
})()

export const getDiamondAmount =  async(accessToken: string):Promise<number> => {
  const req = new GetMemberInfoReq()
  req.setAccessToken(accessToken)

  return (await memberClient.getMemberInfo(req)).toObject().diamond
}
