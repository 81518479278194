























































































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import autosize from "autosize";
import { liveStore } from "@/store";
import throttle from "lodash.throttle";
import { CurrentUser } from "@/store/live";
import Donate from "@/components/donate.vue";
import ModMenu from "@/components/mod_menu.vue";
import { Chat } from "@/lib/api";
import {
  ModWsResponse,
  WsDebugMessage,
  WsGiftMessage,
  WsUserEnterMessage,
} from "@/lib/ws";
// 最大聊天記錄殘留數量
const MAXIMUM_CHATS = 500;
// 清除後聊天記錄殘留數量
const AVAILABLE_CHATS = 250;
const USERENTER_COOLDOWN_IN_SECONDS = 300;
@Component({
  methods: {
    // sendMessage: throttle(async function () {
    //   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //   // @ts-ignore
    //   // eslint-disable-next-line @typescript-eslint/no-this-alias
    //   const that = this;
    //   if (that.isInputValid && !that.isComposing && !that.isSending) {
    //     that.isSending = true;
    //     that.$ws.sendWebsocketMsg("text", {
    //       toUser: "all",
    //       text: that.inputText,
    //     });
    //     that.inputText = "";
    //     await that.$nextTick(() => {
    //       let commentTextarea = that.commentTextarea();
    //       if (commentTextarea) {
    //         autosize(commentTextarea);
    //       }
    //       that.scrollToBottom();
    //     });
    //     that.isSending = false;
    //   }
    // }, 1000),
  },
  components: {
    Donate,
    ModMenu,
  },
})
export default class Chatroom extends Vue {
  inputText = "";
  isSending = false;
  isAutoScrolling = true;
  currentModMenuTarget: Chat | null = null;
  isComposing = false;
  chats: Chat[] = [];
  chatStack: Chat[] = [];
  userEnterCooldownIds: number[] = [];
  get isShowDonate(): boolean {
    return liveStore.isShowDonate;
  }
  get isInputValid(): boolean {
    return (
      !/^\s*$/.test(this.inputText) &&
      !this.isSending &&
      this.inputText.length <= this.CONSTANT_MAX_INPUT_COUNT &&
      !this.isError &&
      !this.isBanned
    );
  }
  CONSTANT_MAX_INPUT_COUNT = 60;
  get isBanned(): boolean {
    return liveStore.bannedNahuoIds.includes(
      String(liveStore.liveDetail?.userInfo.user_id)
    );
  }
  get currentUser(): CurrentUser {
    return liveStore.currentUser;
  }
  get isError(): boolean {
    // return this.$ws.isError
    // TODO
    return false;
  }
  get isMeModerator(): boolean {
    return liveStore.isMeModerator;
  }

  get isMeHost(): boolean {
    return liveStore.isMeHost;
  }

  get isLive(): boolean {
    return liveStore.isLive;
  }

  get debug(): boolean {
    return liveStore.DEBUG;
  }

  @Watch("chats")
  onChatsChanged(val: Chat[]): void {
    if (val.length > 0) {
      if (val.length > MAXIMUM_CHATS) {
        this.chats = this.chats.slice(-AVAILABLE_CHATS);
      }
      this.$nextTick(() => {
        if (this.isAutoScrolling) {
          this.scrollToBottom();
        }
      });
    }
  }

  @Watch("isAutoScrolling")
  onIsAutoScrollingChanged(val: boolean, oldVal: boolean): void {
    if (oldVal === false && val === true) {
      this.transferChats();
    }
  }

  mounted(): void {
    this.init();
  }

  async init(): Promise<void> {
    this.initTextarea();
    this.initScrollDetector();
    this.setWebsocket();
    this.fitChatLogsHeight();
    this.initChats();
  }

  initChats(): void {
    this.chats = liveStore.chats.map((e) => Object.assign({}, e));
  }

  initTextarea(): void {
    let commentTextarea = this.commentTextarea();
    if (commentTextarea) {
      autosize(commentTextarea);
    }
  }

  initScrollDetector(): void {
    let area = document.getElementById("chat-logs");
    if (area) {
      area.addEventListener(
        "scroll",
        throttle((e) => {
          let area = document.getElementById("chat-logs");
          if (area) {
            const clientHeight = area.clientHeight;
            const scrollHeight = area.scrollHeight;
            const scrollTop = area.scrollTop;
            // 單位是px，數字越小越靈敏
            this.isAutoScrolling = scrollHeight - scrollTop - clientHeight < 10;
          }
        }, 300)
      );
    }
  }

  setWebsocket(): void {
    this.$ws.addEventListener("chat", this.receiveMessage);
    this.$ws.addEventListener("userEnter", (e: MessageEvent) => {
      const message: WsUserEnterMessage = JSON.parse(e.data);
      if (
        message.level > 0 &&
        !this.userEnterCooldownIds.includes(message.mid)
      ) {
        this.chats.push({
          banned: 0,
          kick: 0,
          avatar: "",
          content: ` ${message.nickname} 已經進入直播房 ✧*`,
          fromUser: "system",
          gradename: "",
          gradeweight: String(message.level),
          is_virt: "",
          liveid: "",
          manage: "",
          msgid: String(Date.now() + message.nickname + message.level),
          nickname: "",
          rtype: "",
          sendtime: "",
          toUser: "",
        });
        this.userEnterCooldownIds.push(message.mid);
        setTimeout(() => {
          let index = this.userEnterCooldownIds.indexOf(message.mid);
          this.userEnterCooldownIds.splice(index, 1);
        }, USERENTER_COOLDOWN_IN_SECONDS * 1000);
      }
    });
    this.$ws.addEventListener("setMod", this.receiveModMessage);
    this.$ws.addEventListener("giftEnter", this.receiveGift);
    if (liveStore.DEBUG) {
      this.$ws.addEventListener("debug", (e: MessageEvent) => {
        const message: WsDebugMessage = JSON.parse(e.data);
        this.chats.push({
          banned: 0,
          kick: 0,
          avatar: "",
          content: `[DEBUG: ${message.debug_id}]${message.msg}`,
          fromUser: "system",
          gradename: "",
          gradeweight: "",
          is_virt: "",
          liveid: "",
          manage: "",
          msgid: message.debug_id,
          nickname: "",
          rtype: "",
          sendtime: "",
          toUser: "",
        });
      });
    }
  }

  receiveMessage(e: MessageEvent): void {
    const message: Chat = JSON.parse(e.data);
    if (!liveStore.bannedNahuoIds.includes(String(message.fromUser))) {
      if (this.isAutoScrolling) {
        this.chats.push(message);
      } else {
        this.chatStack.push(message);
      }
    }
  }

  scrollToBottom(): void {
    const chatArea = document.getElementById("chat-logs");
    chatArea?.scrollTo(0, chatArea?.scrollHeight);
  }

  commentTextarea(): HTMLElement | null {
    return document.getElementById("comment-textarea");
  }

  showDonate(bool = true): void {
    liveStore._isShowDonate(bool);
  }

  showModeratorMenu(event: PointerEvent, chat: Chat): void {
    const styleOffset = 23;
    if (
      (this.isMeModerator && !this.isMod(chat) && !this.isHost(chat)) ||
      (this.isMeHost && !this.isHost(chat))
    ) {
      if (this.currentModMenuTarget?.msgid === chat.msgid) {
        this.currentModMenuTarget = null;
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const menuPositionX = event.target?.offsetLeft;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const menuPositionY = event.target?.offsetTop + styleOffset;
        const menuDOM = document.getElementById("mod-menu");
        if (menuDOM) {
          menuDOM.style.left = `${menuPositionX}px`;
          menuDOM.style.top = `${menuPositionY}px`;
        }
        this.currentModMenuTarget = chat;
      }
    }
  }

  isMod(chat: Chat): boolean {
    return liveStore.moderatorNahuoIds.includes(String(chat.fromUser));
  }

  isHost(chat: Chat): boolean {
    return liveStore.liveDetail?.anchor.user_id == parseInt(chat.fromUser);
  }

  fitChatLogsHeight(): void {
    const height =
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.getElementsByClassName("chatroom-body")[0].offsetHeight;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    document.getElementById("chat-logs").style.height = `${height}px`;
  }

  transferChats(): void {
    this.chats = this.chats.concat(this.chatStack);
    this.chatStack = [];
  }

  displayName(originalName: string, isMod = false, isHost = false): string {
    if (isHost) {
      return `${originalName}[主播]`;
    } else if (isMod) {
      return `${originalName}[副控]`;
    } else {
      return originalName;
    }
  }

  receiveModMessage(e: MessageEvent): void {
    const message: ModWsResponse = JSON.parse(e.data);
    const myId = liveStore.currentUser.nahuoUid;
    if (myId == message.manageMid && message.manage === 0) {
      // text: `您已被解除副播`,
      this.currentModMenuTarget = null;
    }
  }

  receiveGift(e: MessageEvent): void {
    const message: WsGiftMessage = JSON.parse(e.data);
    this.chats.push({
      banned: 0,
      kick: 0,
      avatar: "",
      content: `${message.nickname} 贈送${message.tipname} x${message.num}`,
      fromUser: "system",
      gradename: "",
      gradeweight: "",
      is_virt: "",
      liveid: "",
      manage: "",
      msgid: String(Date.now() + message.nickname + message.tipid),
      nickname: "",
      rtype: "",
      sendtime: "",
      toUser: "",
    });
  }

  async sendMessage(): Promise<void> {
    if (this.isInputValid && !this.isComposing && !this.isSending) {
      this.isSending = true;
      this.$ws.sendWebsocketMsg("text", {
        toUser: "all",
        text: this.inputText,
      });
      this.inputText = "";
      await this.$nextTick(() => {
        this.initTextarea();
        this.scrollToBottom();
      });
      setTimeout(() => {
        this.isSending = false;
      }, 1000);
    }
  }
}
