/* eslint-disable @typescript-eslint/ban-ts-comment */
import Cookies from "js-cookie";
import Vue from "vue";
const sheet = document.createElement("style")
document.body.appendChild(sheet)


function isMobile(): boolean {
    return document.body.clientWidth < 480
}
function numberWithCommas(x: number): string {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const utilityApi = {
    isMobile,
    numberWithCommas,
}