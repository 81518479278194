






















































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { liveStore } from "@/store";
import { CurrentUser } from "@/store/live";
import { utilityApi } from "@/lib/util";
import postMessageRegister from "@/helpers/post-message-register"
@Component({})
export default class ChatroomGuest extends Vue {
  inputText = "";

  maxInputCount = 60;
  get inputCount(): number {
    return this.inputText.length;
  }
  get isInputLengthValid(): boolean {
    return this.inputCount <= this.maxInputCount;
  }
  get currentUser(): CurrentUser {
    return liveStore.currentUser;
  }

  register(): void {
    postMessageRegister()
  }

  reload(): void {
    window.location.reload();
  }
}
